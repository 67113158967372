import {
    Tabs,
    Tab,
    Box,
    Stack,
    Chip,
    useTheme
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import { useState, useCallback, useEffect } from 'react';

import ChatInterestRequestSummary from './chat-interest-request-summary';
import ChatMessageOverviewList from './chat-message-overview-list';
import ChatAdmin from './chat-admin';
import { useChat } from '../../contexts/chat/chat-provider';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/auth/auth-provider';

const ChatBoxTabLayout = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const logged_in = true;
    
    const [value, setValue] = useState(logged_in ? 0 : 1);
    const [pageTrigger, setPageTrigger] = useState(0);
    const [previousPageTriggerActive, setPreviousPageTriggerActive] = useState(false);

    const [initiated, setInitiated] = useState(false);

    const useChatContext = useChat();
    const useAuthContext = useAuth();

    useEffect(() => {
        if(useChatContext && !initiated){
            useChatContext.setChatBoxBarProperties(t("chat.heading"));
            setInitiated(true);
        }
    }, [useChatContext]);

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
    });

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const listenPageScrollToBottom = useCallback((e) => {
        if(previousPageTriggerActive){
            return;
        }

        if (e.target.scrollTop === (e.target.scrollHeight - e.target.offsetHeight)) { 
            console.log("change page....");

            setPageTrigger((pageTrigger) => pageTrigger + 1);

            setPreviousPageTriggerActive(true);

            setTimeout(() => {
                setPreviousPageTriggerActive(false);
            }, 1000);
        }
    }, [previousPageTriggerActive]);

    useEffect(() => {
        if(!useAuthContext.currentUser){
            handleChange(null, 0);
            useChatContext.resetAllListeners();
        }
    }, [useAuthContext]);

    useEffect(() => {
        if(useChatContext?.chatTabIndex === 1){
            useChatContext.setChatTabIndex(0);
            setValue(1);
        }
    }, [useChatContext]);

    return (
        <>
            <Box 
                sx={{ 
                    width: '100%',
                    height: {xs: '100%', md: '60vh'}
                }}
            >
                <TabContext value={value}>
                    <Box 
                        sx={{ 
                            borderBottom: 1, 
                            borderColor: 'divider' 
                        }}
                    >
                        <Tabs 
                            value={value} 
                            onChange={handleChange} 
                            aria-label="chat tabs"
                            variant="fullWidth"
                        >
                            {
                                logged_in
                                ?
                                <Tab 
                                    icon={
                                        useChatContext && useChatContext.getTotalUnreadUserMessagesCount()
                                        ?
                                        <Chip 
                                            size='small' 
                                            label={useChatContext?.getTotalUnreadUserMessagesCount()} 
                                            sx={{
                                                backgroundColor: theme.palette.primary.main, color: 'white',
                                                display: useChatContext?.getTotalUnreadUserMessagesCount() > 0 ? 'flex': 'none'
                                            }}
                                        />
                                        :
                                        <></>
                                    }
                                    label="Messages" 
                                    iconPosition="end"
                                    {...a11yProps(0)} 
                                />
                                :
                                <></>
                            }
                            
                            <Tab 
                                icon={
                                    useChatContext && useChatContext.getTotalUnreadAdminMessagesCount()
                                    ?
                                    <Chip 
                                        size='small' 
                                        label={useChatContext?.getTotalUnreadAdminMessagesCount()} 
                                        sx={{
                                            backgroundColor: theme.palette.primary.main, color: 'white',
                                            display: useChatContext?.getTotalUnreadAdminMessagesCount() > 0 ? 'flex': 'none'
                                        }}
                                    />
                                    :
                                    <></>
                                } 
                                label="Admin support" 
                                iconPosition="end"
                                {...a11yProps(1)} 
                            />
                        </Tabs>
                    </Box>

                    <TabPanel value={0} sx={{pt: 0, pb: 2}}>
                        <Stack 
                            className='hideScroll'
                            sx={{
                                overflow: 'scroll',
                                height: {xs: '100%', md: 'calc(60vh - 43px)'}
                            }}
                            onScroll={listenPageScrollToBottom}
                        >
                            <ChatInterestRequestSummary />
                            <ChatMessageOverviewList pageTrigger={pageTrigger}/>
                        </Stack>
                    </TabPanel>

                    <TabPanel value={1} sx={{pt: 0, pb: 2, px: 0}}>
                        <ChatAdmin />
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    );
}

export default ChatBoxTabLayout;