import { useCallback, useEffect, useRef, useState } from "react";
import ChatMessageBody from "./chat-message-body";
import ChatBoxTabLayout from "./chat-box-tab-layout";
import ChatField from "./chat-field";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useReadUserMessageMutation, useReplyToMessageMutation } from "../../services/chat-api";
import APIErrorLayout from "../error/api-error/api-error-layout";
import SkeletonComponent from "../../components/loading/skeleton-component";
import SnackbarComponent from "../../components/snack-bar/snack-bar-component";
import { useChat } from "../../contexts/chat/chat-provider";
import { getDatabase, ref, onValue, limitToLast, query, endBefore } from "firebase/database";
import { useAuth } from "../../contexts/auth/auth-provider";

const ChatPersonal = () => {
    const { t } = useTranslation();
    const profile_locale = "profile";

    const [userDetails, setUserDetails] = useState(null);

    const [chat, setChat] = useState([]);

    const [pageIndex, setPageIndex] = useState(0);

    const useChatContext = useChat();

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [loadPrevMessageState, setLoadPrevMessageState] = useState({
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: null
    });

    const authState = useAuth();
    const database = getDatabase(authState?.firebaseApp);
    const [topMessageKey, setTopMessageKey] = useState(null);

    const chatRef = useRef(null);

    const scrollBottomOfChat = useCallback(() => {
        chatRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }, []);

    const modifyLastOnline = useCallback((lastOnline) => {
        const diff = new Date() - new Date(lastOnline);
        
        const seconds = diff * 0.001;

        if(seconds < 60){
            return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.minute`)}`;
        }
        else{
            const minutes = seconds / 60;

            if(minutes < 60){
                if(minutes <= 5){
                    return `${t(`${profile_locale}.online.5`)} ${t(`${profile_locale}.online.minutes`)}`;
                }
                else if(minutes <= 10){
                    return `${t(`${profile_locale}.online.10`)} ${t(`${profile_locale}.online.minutes`)}`;
                }
                else if(minutes <= 15){
                    return `${t(`${profile_locale}.online.15`)} ${t(`${profile_locale}.online.minutes`)}`;
                }
                else if(minutes <= 30){
                    return `${t(`${profile_locale}.online.30`)} ${t(`${profile_locale}.online.minutes`)}`;
                }
                else if(minutes <= 45){
                    return `${t(`${profile_locale}.online.45`)} ${t(`${profile_locale}.online.minutes`)}`;
                }

                return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.hour`)}`;
            }
            else{
                const hrs = minutes / 60;

                if(hrs < 24){
                    if(hrs <= 2){
                        return `${t(`${profile_locale}.online.2`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 3){
                        return `${t(`${profile_locale}.online.3`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 4){
                        return `${t(`${profile_locale}.online.4`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 5){
                        return `${t(`${profile_locale}.online.5`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 6){
                        return `${t(`${profile_locale}.online.6`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 7){
                        return `${t(`${profile_locale}.online.7`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 8){
                        return `${t(`${profile_locale}.online.8`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 9){
                        return `${t(`${profile_locale}.online.9`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 10){
                        return `${t(`${profile_locale}.online.10`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 11){
                        return `${t(`${profile_locale}.online.11`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 12){
                        return `${t(`${profile_locale}.online.12`)} ${t(`${profile_locale}.online.hours`)}`;
                    }

                    return `${t(`${profile_locale}.online.few`)} ${t(`${profile_locale}.online.hours`)}`;
                }
                else{
                    const days = hrs / 24;

                    if(days < 7){
                        if(days <= 1){
                            return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.day`)}`;
                        }
                        else if(days <= 2){
                            return `${t(`${profile_locale}.online.2`)} ${t(`${profile_locale}.online.days`)}`;
                        }
                        else if(days <= 3){
                            return `${t(`${profile_locale}.online.3`)} ${t(`${profile_locale}.online.days`)}`;
                        }
                        else if(days <= 4){
                            return `${t(`${profile_locale}.online.4`)} ${t(`${profile_locale}.online.days`)}`;
                        }
                        else if(days <= 5){
                            return `${t(`${profile_locale}.online.5`)} ${t(`${profile_locale}.online.days`)}`;
                        }
                        else if(days <= 6){
                            return `${t(`${profile_locale}.online.6`)} ${t(`${profile_locale}.online.days`)}`;
                        }
    
                        return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.week`)}`;
                    }
                    else{
                        const weeks = days / 7;

                        if(weeks < 4){
                            if(weeks <= 2){
                                return `${t(`${profile_locale}.online.2`)} ${t(`${profile_locale}.online.weeks`)}`;
                            }
                            else if(weeks <= 3){
                                return `${t(`${profile_locale}.online.3`)} ${t(`${profile_locale}.online.weeks`)}`;
                            }

                            return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.month`)}`;
                        }
                        else{
                            const months = weeks / 4;

                            if(months <= 2){
                                return `${t(`${profile_locale}.online.2`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 3){
                                return `${t(`${profile_locale}.online.3`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 4){
                                return `${t(`${profile_locale}.online.4`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 5){
                                return `${t(`${profile_locale}.online.5`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 6){
                                return `${t(`${profile_locale}.online.6`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 7){
                                return `${t(`${profile_locale}.online.7`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 8){
                                return `${t(`${profile_locale}.online.8`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 9){
                                return `${t(`${profile_locale}.online.9`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 10){
                                return `${t(`${profile_locale}.online.10`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 11){
                                return `${t(`${profile_locale}.online.11`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 12){
                                return `${t(`${profile_locale}.online.12`)} ${t(`${profile_locale}.online.months`)}`;
                            }

                            return `${t(`${profile_locale}.online.few`)} ${t(`${profile_locale}.online.years`)}`;
                        }
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if(useChatContext && !userDetails){
            const user = useChatContext.activeUserChat;

            const lastOnline = `${t(`${profile_locale}.online.online`)} ${modifyLastOnline(user.lastOnline)} ${t(`${profile_locale}.online.ago`)}`;
            
            useChatContext.setChatBoxBarProperties(
                user.firstName, 
                lastOnline, 
                user.profileImg ? user.profileImg : '', 
                () => {useChatContext.setChatBoxBody(<ChatBoxTabLayout />)}, 
                user.memberId
            );

            setUserDetails(user);
        }        
    }, [useChatContext, userDetails]);

    const loadPreviousMessagesAtTheTop = useCallback(async (page) => {
        setLoadPrevMessageState({
            isLoading: true
        });

        try{
            const id = authState?.currentUser.uuid;
            const chatId = userDetails.id;

            let chatQuery;

            if(topMessageKey){
                chatQuery = query(ref(database, 'user/chat/' + id + "/" + chatId), limitToLast(10), endBefore(null, topMessageKey));
            }
            else{
                chatQuery = query(ref(database, 'user/chat/' + id + "/" + chatId), limitToLast(10));
            }
        
            let topKey = null;

            onValue(chatQuery, (snapshot) => {
                if(snapshot.exists()){
                    console.log("Received message list from the user " + chatId + ", for page " + page);
    
                    const messageArray = [];
    
                    snapshot.forEach((item) => {
                        const message = item.val();
                        const found = chat.some(el => el.id === message.id);

                        if(!found) messageArray.push(message);

                        topKey = item.key;
                    });
                    
                    const array = [...messageArray, ...chat];
                    console.log(array);
                    setChat(array);

                    console.log(topKey);
                    setTopMessageKey(topKey);
                }
    
                setLoadPrevMessageState({
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                });
            }, {onlyOnce: true});
        }
        catch(e){
            setLoadPrevMessageState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: "Unable to load the chat"
            });
        }
    }, [chat, topMessageKey, userDetails]);

    const loadNewMessageAtTheBottom = useCallback((message) => {
        const array = [...chat];

        console.log(array);
        console.log(message);

        let needUpdate = false;
        for(let i = 0; i < message.length; i++){
            const found = array.some(el => el.id === message.id);

            if(!found){
                array.push(message[i]);

                if(!needUpdate) needUpdate = true;
            }
        }

        if(needUpdate){
            setChat(array);
        }
    }, [chat]);

    useEffect(() => {
        if(userDetails && useChatContext.newlyReceivedMessageList.length > 0){
            const newlyReceivedMessageList = [...useChatContext?.newlyReceivedMessageList];
            
            console.log("New message noted... ");
            console.log(userDetails);
            console.log(newlyReceivedMessageList);

            for(let i = 0; i < newlyReceivedMessageList.length; i++){
                if(newlyReceivedMessageList[i].id === userDetails.id){
                    loadNewMessageAtTheBottom(newlyReceivedMessageList[i].message);
                    break;
                }
            }

            setTimeout(() => {
                scrollBottomOfChat();
            }, 250);
        }
    }, [useChatContext.newlyReceivedMessageList, userDetails]);

    useEffect(() => {
        if(userDetails){
            loadPreviousMessagesAtTheTop(pageIndex);
        }
    }, [pageIndex, userDetails]);

    

    const changePage = useCallback(() => {
        let page = pageIndex;
        page += 1;

        setPageIndex(page);
    }, [pageIndex]);



    const sendMessage = useCallback((message) => {
        const messageData = {
            "message": message
        }

        console.log(messageData);
        
        if(userDetails){
            console.log(userDetails.id);
            replyToUser({chatId: userDetails.id, data: messageData});
        }
    }, [userDetails]);



    const [
        replyToUser,
        {
            isLoading: isLoadingReplyToUser,
            isSuccess: isSuccessReplyToUser,
            data: dataReplyToUser,
            isError: isErrorReplyToUser,
            error: errorReplyToUser
        }
    ] = useReplyToMessageMutation();

    useEffect(() => {
        if (isErrorReplyToUser && errorReplyToUser) {
            console.log(errorReplyToUser);
            const errorMsg = errorReplyToUser?.data?.error?.message;

            setShowSnackBar({
                state: true,
                message: errorMsg === undefined ? "Something went wrong" : errorMsg,
                color: "red"
            });
        }
    }, [isErrorReplyToUser, errorReplyToUser]);



    useEffect(() => {
        if(userDetails && useChatContext.getTotalUnreadSpecificUserMessagesCount(userDetails.id) > 0){
            readUserMessage(userDetails.id);
        }
    }, [useChatContext]);
    
    const [
        readUserMessage,
        {
            isSuccess: isSuccessReadUserMessage,
            isError: isErrorReadUserMessage,
            error: errorReadUserMessage
        }
    ] = useReadUserMessageMutation();

    useEffect(() => {
        if (isSuccessReadUserMessage) {
            console.log("User message successfully read");
            useChatContext.clearUserUnreadStatus(userDetails.id);
        }
        else if(isErrorReadUserMessage && errorReadUserMessage){
            console.log("User message failed to read");
            console.log(errorReadUserMessage);
        }
    }, [isSuccessReadUserMessage, isErrorReadUserMessage, errorReadUserMessage]);



    let body;

    if (loadPrevMessageState.isLoading) {
        {
            authState?.currentUser && chat.length > 0
            ?
            body = <>
                <ChatMessageBody chats={chat} height="100px"/>
                <ChatField submit={sendMessage}/>
            </>
            :
            body = <Stack
                display='flex'
                direction='column'
            >
                <Stack 
                    direction='row' 
                    justifyContent='start'
                    sx={{width: '50%'}}
                >
                    <SkeletonComponent 
                        variant='rounded' 
                        height="200px"
                        width="230px"
                        sx={{
                            mt: 3,
                            ml: 3,
                            borderRadius: '50px 50px 50px 0px',
                        }}
                    />
                </Stack>

                <Stack 
                    direction='row' 
                    justifyContent='end'
                    sx={{width: '100%'}}
                >
                    <SkeletonComponent 
                        variant='rounded' 
                        width="230px"
                        height="200px"
                        sx={{
                            mt: 3,
                            mr: 3,
                            borderRadius: '50px 50px 0px 50px',
                        }}
                    />
                </Stack>
            </Stack>
        }
    }
    else if (loadPrevMessageState.isError) {
        console.log(loadPrevMessageState.error);
        body = <APIErrorLayout error={loadPrevMessageState.error}/>
    }
    else if(loadPrevMessageState.isSuccess){
        body = 
            authState?.currentUser
            ?
            <>
                <ChatMessageBody chats={chat} changePage={changePage} chatRef={chatRef} height="100px"/>
                <ChatField submit={sendMessage}/>
            </>
            :
            <></>
    }



    return (
        <>
            <Box 
                className='hideScroll'
                sx={{
                    overflow: 'scroll',
                    height: {xs: '100%', md: '60vh'},
                    mb: {xs: 20, md: 0}
                }}    
            >
                {body}
            </Box>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default ChatPersonal;