import PropTypes from 'prop-types';

import {
    Box,
    BottomNavigation,
    BottomNavigationAction,
    useTheme,
    Divider,
    Badge
} from '@mui/material';

import { useEffect, useState } from 'react';

import HomeIcon from '../../assets/icons/home-icon';
import AccountMultipleIcon from "../../assets/icons/account-multiple-icon";
import MagnifyIcon from '../../assets/icons/magnify-icon';
import HeartOutlineIcon from '../../assets/icons/heart-outline-icon';
import ChatOutlineIcon from '../../assets/icons/chat-outline-icon';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../../contexts/chat/chat-provider';

const main_nav_options = [
    {label: 'feed', icon: <HomeIcon style={{height: '20px', width: '20px'}}/>, redirect: '/feed'},
    {label: 'search', icon: <MagnifyIcon style={{height: '20px', width: '20px'}}/>, redirect: '/search'},
    {label: 'matches', icon: <AccountMultipleIcon style={{height: '20px', width: '20px'}}/>, redirect: '/matches'},
    {label: 'interests', icon: <HeartOutlineIcon style={{height: '20px', width: '20px'}}/>, redirect: '/interests'},
    {label: 'chat', icon: <ChatOutlineIcon style={{height: '20px', width: '20px'}}/>, redirect: '/chat'}
];

const BottomNavigationBar = ({selectedValue}) => {
    const { t } = useTranslation();
    const profile_navigation_locale = "profile_navigation";

    const theme = useTheme();

    const navigate = useNavigate();

    const [value, setValue] = useState(selectedValue);

    const [initiated, setInitiated] = useState(false);

    const useChatContext = useChat();

    useEffect(() => {
        if(useChatContext && !initiated){
            setInitiated(true);
        }
    }, [useChatContext, initiated]);

    
    return (
        <Box sx={{ 
            width: '100%',
            position: 'fixed', 
            bottom: 0, 
            left: 0, 
            right: 0,
            zIndex: 100,
            display: {xs: 'block', md: 'none'}
        }}>
            <Divider />

            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    console.log(newValue);
                    setValue(newValue);
                    navigate(main_nav_options[newValue].redirect);
                }}
            >
                {
                    main_nav_options.map((item, index) => {
                        return <BottomNavigationAction 
                            key={index}
                            label={t(`${profile_navigation_locale}.${item.label}`)} 
                            icon={
                                index === 4
                                ?
                                <Badge color="primary" badgeContent={useChatContext?.getTotalUnreadMessagesCount()}>
                                    {item.icon}
                                </Badge>
                                :
                                <>
                                    {item.icon}
                                </>
                            }
                            sx={{
                                "& .Mui-selected": {
                                    color: theme.palette.primary.main,
                                    fontWeight: 510
                                },
                                "& .MuiBottomNavigationAction-label": {
                                    fontWeight: 510,
                                    fontSize: 10
                                },
                                "& .MuiBottomNavigationAction-label.Mui-selected": {
                                    fontSize: 10
                                }
                            }}
                        />
                    })
                }
            </BottomNavigation>
        </Box>
    );
}

export default BottomNavigationBar;

BottomNavigationBar.propType = {
    selectedValue: PropTypes.number
}