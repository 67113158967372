import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import PartnerInfoBoxView from "../partner-info-box-view";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { 
    SelectionComponent, 
    TextFieldComponent,
    CurrencyComponent
} from "../../../components/form";
import SliderComponent from "../../../components/form/slider-component";
import MultipleSelectionComponent from "../../../components/form/multiple-selection-component";

import {
    educationLevelList
} from '../../../assets/data/profile';

import { useGetSpecificMasterDataQuery } from '../../../services/master-data-api';
import { useGetOnCallPartnerPreferenceDetailsMutation } from '../../../services/partner-preference-api';

import APIErrorLayout from '../../error/api-error/api-error-layout';
import FormProviderComponent from "../../../components/form/form-provider-component";
// import { currencies } from '../../../assets/data/currencies';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';

var cc = require('currency-codes');

const PartnerProfessionalPreferenceBody = () => {
    const { t } = useTranslation();
    const partner_professional_locale = "partner_preference.professional";

    const edit_title = t(`${partner_professional_locale}.edit_title`);

    const incomeRange = [0, 1000000];

    const [partnerData, setPartnerData] = useState({});

    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        const list = [];

        for(let i = 0; i < cc.data.length; i++){
            const option = cc.data[i];
            list.push({id: i, label: `${option.code} (${option.currency})`, value: option.currency});
        }

        setCurrencies(list);
    }, [cc]);

    const formSchema = yup.object().shape({
        educationLevel: yup.array(),
        occupation: yup.string(),
        employmentType: yup.array(),
        income: yup.array(),
        currency: yup.string()
    });

    const defaultValues = {
        educationLevel: [],
        occupation: '',
        employmentType: [],
        income: incomeRange,
        currency: ''
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    const formValue = watch();

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [employmentTypeList, setEmploymentTypeList] = useState([]);
    const {isLoading: isLoadingEmploymentType, isSuccess: isSuccessEmploymentType, data: dataEmploymentType, isError: isErrorEmploymentType, error: errorEmploymentType} = useGetSpecificMasterDataQuery('employment-type');
    useEffect(() => {
        if(isSuccessEmploymentType && dataEmploymentType){
            console.log("Employment type master data...");
            console.log(dataEmploymentType);

            setEmploymentTypeList(getListData(dataEmploymentType.data));
        }
        else if(isErrorEmploymentType && errorEmploymentType){
            console.log("Employment type master data error...");
            console.log(errorEmploymentType);

            setEmploymentTypeList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessEmploymentType, dataEmploymentType, isErrorEmploymentType, errorEmploymentType]);

    const [
        getPartnerPreferenceDetails,
        {
            isLoading: isLoadingPartnerPreference, 
            isSuccess: isSuccessPartnerPreference, 
            data: dataPartnerPreference, 
            isError: isErrorPartnerPreference, 
            error: errorPartnerPreference
        }
    ]= useGetOnCallPartnerPreferenceDetailsMutation();

    const getMultiValueAssignedValues = useCallback((valuesArray) => {
        const newArray = [];

        for(let i = 0; i < valuesArray?.length; i++){
            newArray.push({label: valuesArray[i], value: valuesArray[i]});
        }
        
        return newArray;
    }, []);

    const setDefaultValues = useCallback((data) => {
        setFormValue("educationLevel", getMultiValueAssignedValues(data.educationLevel));
        setFormValue("occupation", data.occupation);
        setFormValue("employmentType", getMultiValueAssignedValues(data.employmentType));
        setFormValue("income", data.maxAnnualIncome === 0 ? incomeRange : [data.minAnnualIncome, data.maxAnnualIncome]);
        setFormValue("currency", data.currency);
    }, []);

    useEffect(() => {
        if(isSuccessPartnerPreference && dataPartnerPreference){
            console.log("Partner preference data...");
            console.log(dataPartnerPreference);

            setPartnerData(dataPartnerPreference.data);
            setDefaultValues(dataPartnerPreference.data);
        }
        else if(isErrorPartnerPreference && errorPartnerPreference){
            console.log("Partner preference data error...");
            console.log(errorPartnerPreference);
        }
    }, [isSuccessPartnerPreference, dataPartnerPreference, isErrorPartnerPreference, errorPartnerPreference]);

    const callPartnerPreferenceDetails = useCallback(async () => {
        await getPartnerPreferenceDetails();
    }, []);

    useEffect(() => {
        callPartnerPreferenceDetails();
    }, []);

    const editCallback = useCallback((edited) => {
        if(edited){
            callPartnerPreferenceDetails();
        }
        else{
            setDefaultValues(partnerData);
        }
    }, [partnerData]);

    const canEditIncome = useCallback(() => {
        if(!formValue.currency){
            return t(`${partner_professional_locale}.currency_required`);
        }
        else{
            return null;
        }
    }, [formValue]);

    const validateOccupation = useCallback((givenValue) => {
        return givenValue.trim().length <= 30;
    }, []);

    return (
        <>
            {
                isErrorPartnerPreference && errorPartnerPreference
                ?
                <APIErrorLayout error={errorPartnerPreference} />
                :
                <Stack
                    direction='column'
                    sx={{
                        width: {xs: '100%', lg: '60%'},
                        my: 5
                    }}
                >
                    <FormProviderComponent methods={formMethods} onSubmit={() => {}}>
                        <PartnerInfoBoxView 
                            label={t(`${partner_professional_locale}.education`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='educationLevel'
                                    label={t(`${partner_professional_locale}.education`)}
                                    list={educationLevelList}
                                />
                            }
                            editedValue={formValue.educationLevel}
                            modifiedAttribute={["educationLevel"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_professional_locale}.occupation`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <TextFieldComponent 
                                    name='occupation'
                                    label={t(`${partner_professional_locale}.occupation`)}
                                />
                            }
                            editedValue={formValue.occupation}
                            modifiedAttribute={["occupation"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                            validation={validateOccupation}
                            errorMessage={t(`${partner_professional_locale}.invalid_occupation`)}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_professional_locale}.employment_type`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='employmentType'
                                    label={t(`${partner_professional_locale}.employment_type`)}
                                    list={employmentTypeList}
                                />
                            }
                            editedValue={formValue.employmentType}
                            modifiedAttribute={["employmentType"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_professional_locale}.currency`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <SelectionComponent 
                                    name='currency'
                                    label={t(`${partner_professional_locale}.currency`)}
                                    list={currencies}
                                />
                            }
                            editedValue={formValue.currency}
                            modifiedAttribute={["currency"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_professional_locale}.annual_income`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <Stack sx={{px: 3}}>
                                    <SliderComponent 
                                        name='income'
                                        label={t(`${partner_professional_locale}.annual_income`)}
                                        range={incomeRange}
                                        step={10000}
                                    />
                                </Stack>
                            }
                            editedValue={formValue.income}
                            modifiedAttribute={["minAnnualIncome", "maxAnnualIncome"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                            editCallValidation={canEditIncome}
                        />
                    </FormProviderComponent>
                </Stack>
            }
        </>
    );
}

export default PartnerProfessionalPreferenceBody;