import PropTypes from 'prop-types';

import { Typography, useTheme, Stack, Link } from "@mui/material";

import ChevronRightIcon from '../../assets/icons/chevron-right-icon';
import { useTranslation } from "react-i18next";
import { useCallback } from 'react';
 
const ProfileValueText = ({value, label, callback, privateView, restrictedText=false}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_locale = "profile";

    const getTextColor = () => {
        if(restrictedText && !privateView){
            return 'transparent';
        }

        return (!value || value === "") && !privateView ? theme.palette.primary.main : '#6D6D6D';
    } 

    const capitalizeFirstLetter = useCallback((value) => {
        if(!isNaN(value) || value.length <= 1){
            return value;
        }

        let newValue = value;

        // Remove underscore
        if(newValue.includes('_')){
            newValue = newValue.replace('_', ' ');
        }

        return newValue.charAt(0).toUpperCase() + newValue.slice(1).toLowerCase();
    }, []);
    
    return (
        <>
            {
                ((!value && value !== 0) || value === "") && privateView
                ?
                <Link onClick={callback} underline='none' sx={{cursor: 'pointer'}}>
                    <Stack 
                        direction='row'
                        justifyContent='start'
                        alignItems='center'
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: 16,
                                color: theme.palette.primary.main
                            }}
                        >
                            {`${t(`${profile_locale}.add`)} ${label.toLowerCase()}`}
                        </Typography>

                        <ChevronRightIcon style={{width: '22px', color: theme.palette.primary.main}}/>
                    </Stack>
                </Link>
                :
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: 16,
                        color: getTextColor(),
                        textShadow: restrictedText && !privateView ? '0 0 8px #6D6D6D' : 'none'
                    }}
                >
                    {((!value && value !== 0) || value === "") ? t(`${profile_locale}.unknown`) : capitalizeFirstLetter(value)}
                </Typography>
            }
        </>
    );
}

export default ProfileValueText;

ProfileValueText.propType = {
    value: PropTypes.string, 
    label: PropTypes.string, 
    callback: PropTypes.func,
    publicView: PropTypes.bool,
    restrictedText: PropTypes.bool
}