import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import { 
    TextField,
    MenuItem,
    Grid
} from "@mui/material";

import TextFieldComponent from './text-field-component';

var cc = require('currency-codes');

const CurrencyComponent = ({
    label, 
    name, 
    currency_name, 
    currency_label='',
    required=false,
    sx
}) => {
    const { control } = useFormContext();

    return (
        <>
            <Grid 
                container
                direction='row'
                alignItems='center'
                sx={{width: '100%'}}
            >
                <Grid xs={3}>
                    <Controller
                        name={currency_name}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                select
                                label={currency_label}
                                value={field.value}
                                onChange={(event) => {
                                    field.onChange(event.target.value);
                                }}
                                fullWidth
                                error={!!error}
                                helperText={error}
                                sx={{
                                    backgroundColor: '#919EAB14',
                                    "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: '#919EAB14'
                                        },
                                        "&.Mui-focused": {
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: '#919EAB14'
                                            },
                                        },
                                    }
                                }}
                                InputLabelProps={{
                                    style: { color: 'rgba(145, 158, 171, 1)' },
                                }}
                            >
                                {cc.data.map((option) => (
                                    <MenuItem 
                                        key={option.code} 
                                        value={option.currency}
                                    >
                                        {`${option.code} (${option.currency})`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>

                <Grid xs={9}>
                    <TextFieldComponent 
                        name={name}
                        label={label}
                        required={required}
                        fullWidth
                        type='number'
                        sx={{
                            ...sx
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default CurrencyComponent;

CurrencyComponent.propType = {
    label: PropTypes.string,
    name: PropTypes.string,
    currency_name: PropTypes.string,
    required: PropTypes.bool,
    selectedCurrencyValue: PropTypes.string,
    selectedValue: PropTypes.string,
    sx: PropTypes.object,
    currency_label: PropTypes.string
}