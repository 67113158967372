import { useCallback, useState } from "react";

import SignupConfirmation from './signup-confirmation';
import SignupForm from "./signup-form";
import VerifyEmailLayout from '../../profile-verification/verify-email/verify-email-layout';

const SignupLayout = () => {
    const [bodyIndex, setBodyIndex] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const updateBody = useCallback(() => {
        setBodyIndex((id) => id + 1);
    }, []);

    let body;

    if(bodyIndex === 0){
        body = <SignupForm signupSuccessCallback={updateBody} setEmail={setEmail} setPassword={setPassword}/>
    }
    else if(bodyIndex === 1){
        body = <VerifyEmailLayout verifiedCallback={updateBody} skipCallback={updateBody} userEmail={email}/>
    }
    else{
        body = <SignupConfirmation email={email} password={password}/>
    }

    return(
        <>
            {
                body
            }
        </>
    );
}

export default SignupLayout;