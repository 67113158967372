import {
    Avatar,
    Stack,
    List,
    Divider,
    Typography,
    Link,
    Alert
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import ChatInterestDetailView from './chat-interest-detail-view';
import { useGetAllReceivedInterestsMutation } from '../../services/interest-api';
import SkeletonComponent from '../../components/loading/skeleton-component';
import APIErrorLayout from '../error/api-error/api-error-layout';
import EmptyLayout from '../error/empty/empty-layout';
import { useChat } from '../../contexts/chat/chat-provider';
import { useTranslation } from 'react-i18next';
import ChatBoxTabLayout from './chat-box-tab-layout';
import { useAuth } from '../../contexts/auth/auth-provider';

const ChatInterestOverviewList = () => {
    const { t } = useTranslation();
    const chat_locale = "chat";
    const profile_locale = "profile";

    const useChatContext = useChat();
    const useAuthContext = useAuth();

    const [initiated, setInitiated] = useState(false);

    const [pendingInterestList, setPendingInterestList] = useState([]);

    const [
        getAllReceivedInterests, 
        {
            isLoading: isLoadingReceivedInterests, 
            isSuccess: isSuccessReceivedInterests, 
            data: dataReceivedInterests, 
            isError: isErrorReceivedInterests, 
            error: errorReceivedInterests
        }
    ] = useGetAllReceivedInterestsMutation();

    useEffect(() => {
        if(isSuccessReceivedInterests && dataReceivedInterests){
            console.log("Received interest data...");
            console.log(dataReceivedInterests);

            setPendingInterestList(dataReceivedInterests.data.content);
        }
    }, [isSuccessReceivedInterests, dataReceivedInterests]);

    useEffect(() => {
        getAllReceivedInterests({page: 0, status: 'PENDING'});
    }, []);

    useEffect(() => {
        if(!initiated && useChatContext){
            useChatContext.setChatBoxBarProperties(t(`${chat_locale}.pending_request_chat_box_heading`), "", "", () => {useChatContext.setChatBoxBody(<ChatBoxTabLayout />)});
            setInitiated(true);
        }
    }, [useChatContext, initiated]);

    const openInterestRequest = useCallback((item) => {
        useChatContext.setActiveInterestRequestSentUser(item);
        useChatContext.setChatBoxBody(<ChatInterestDetailView />);
    }, [useChatContext]);

    const isUserProfileAvailable = (item) => item.status !== 'DELETED' && item.status !== 'INACTIVE' && !item.blockedByProfile;

    let body;

    if (isLoadingReceivedInterests) {
        body = <Stack
            display='flex'
            direction='column'
        >
            {
                [0, 1, 2, 3].map((item, index) => {
                    return <SkeletonComponent 
                        key={index}
                        variant='rounded' 
                        height="125px"
                        sx={{
                            my: 1,
                            mx: 2
                        }}
                    />
                })
            }
        </Stack>
    }
    else if (isErrorReceivedInterests) {
        console.log(errorReceivedInterests);

        body = <APIErrorLayout error={errorReceivedInterests}/>;
    }
    else if(isSuccessReceivedInterests){
        body = <List
            className='hideScroll'
            sx={{
                overflow: 'auto',
                height: '60vh',
                '& ul': { padding: 0 },
                px: 2
            }}
        >
            {
                pendingInterestList.length > 0
                ?
                pendingInterestList.map((item, index) => {
                    return <>
                        <Link 
                            underline='none'
                            sx={{
                                cursor: !isUserProfileAvailable(item) ? 'default' : 'pointer',
                            }}
                            onClick={() => isUserProfileAvailable(item) ? openInterestRequest(item) : null}
                        >
                            <Stack
                                key={index}
                                direction='row'
                                alignItems='center'
                                py={2}
                            >
                                <Avatar 
                                    alt={item.firstName} 
                                    src={
                                        item.profileImages?.length > 0 
                                        ? 
                                        item.profileImages[0] 
                                        : 
                                        useAuthContext?.currentUser?.gender !== 'MALE' ? '/assets/images/profile/profile_male.jpg' : '/assets/images/profile/profile_female.jpg'
                                    } 
                                    sx={{
                                        width: 88,
                                        height: 88
                                    }}
                                />

                                <Stack
                                    direction='column'
                                    pl={2}
                                    spacing={0.5}
                                >
                                    {
                                        isUserProfileAvailable(item)
                                        ?
                                        <Stack direction='row' spacing={4} alignItems='center'>
                                            <Typography
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: 18,
                                                    color: 'black'
                                                }}
                                            >
                                                {item.firstName}
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontWeight: 400,
                                                    fontSize: 12,
                                                    color: '#6D6D6D'
                                                }}
                                            >
                                                <ul style={{listStyleType: 'circle'}}>
                                                    <li>{item.countryOfLiving}</li>
                                                </ul>
                                            </Typography>
                                        </Stack>
                                        :
                                        <></>
                                    }

                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: '#6D6D6D',
                                            py: 1
                                        }}
                                    >
                                        {item.memberId}
                                    </Typography>

                                    {
                                        !isUserProfileAvailable(item)
                                        ?
                                        <Alert variant="outlined" severity="error">
                                            {
                                                item.blockedByProfile
                                                ?
                                                t(`${profile_locale}.alert.profile_blocked`)
                                                :
                                                item.status === 'DELETED' 
                                                ? 
                                                t(`${profile_locale}.alert.profile_removed`) 
                                                : 
                                                t(`${profile_locale}.alert.profile_inactive`)
                                            }
                                        </Alert>
                                        :
                                        <></>
                                    }
                                </Stack>
                            </Stack>
                        </Link>

                        <Divider sx={{opacity: 0.5}}/>
                    </>
                })
                :
                <EmptyLayout />
            }
        </List>
    }

    return (
        <>
            {body}
        </>
    );
}

export default ChatInterestOverviewList;