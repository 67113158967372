import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import PartnerInfoBoxView from "../partner-info-box-view";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import MultipleSelectionComponent from "../../../components/form/multiple-selection-component";

import { useGetSpecificMasterDataQuery, useGetSubCasteWithCasteIDMutation } from '../../../services/master-data-api';
import { useGetOnCallPartnerPreferenceDetailsMutation, useUpdatePartnerPreferenceMutation } from '../../../services/partner-preference-api';

import APIErrorLayout from '../../error/api-error/api-error-layout';
import FormProviderComponent from "../../../components/form/form-provider-component";
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';

const PartnerReligiousPreferenceBody = () => {
    const { t } = useTranslation();
    const partner_religious_locale = "partner_preference.religious";

    const edit_title = t(`${partner_religious_locale}.edit_title`);

    const [partnerData, setPartnerData] = useState({});

    const [needSubCasteValidation, setNeedSubCasteValidation] = useState(false);
    const [prevCasteList, setPrevCasteList] = useState([]);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const formSchema = yup.object().shape({
        religion: yup.array(),
        caste: yup.array(),
        subCaste: yup.array(),
        dosham: yup.array()
    });

    const defaultValues = {
        religion: [],
        caste: [],
        subCaste: [],
        dosham: []
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    const formValue = watch();

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [religionList, setReligionList] = useState([]);
    const {isLoading: isLoadingReligion, isSuccess: isSuccessReligion, data: dataReligion, isError: isErrorReligion, error: errorReligion} = useGetSpecificMasterDataQuery('religion');
    useEffect(() => {
        if(isSuccessReligion && dataReligion){
            console.log("Religion master data...");
            console.log(dataReligion);

            setReligionList(getListData(dataReligion.data));
        }
        else if(isErrorReligion && errorReligion){
            console.log("Religion master data error...");
            console.log(errorReligion);

            setReligionList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessReligion, dataReligion, isErrorReligion, errorReligion]);

    const [castList, setCastList] = useState([]);
    const {isLoading: isLoadingCaste, isSuccess: isSuccessCaste, data: dataCaste, isError: isErrorCaste, error: errorCaste} = useGetSpecificMasterDataQuery('caste');
    useEffect(() => {
        if(isSuccessCaste && dataCaste){
            console.log("Caste master data...");
            console.log(dataCaste);

            setCastList(getListData(dataCaste.data));
        }
        else if(isErrorCaste && errorCaste){
            console.log("Caste master data error...");
            console.log(errorCaste);

            setCastList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessCaste, dataCaste, isErrorCaste, errorCaste]);

    const [subCastList, setSubCastList] = useState([]);
    const [getSubCasteList, {isLoading: isLoadingSubCasteList, isSuccess: isSuccessSubCasteList, data: dataSubCasteList, isError: isErrorSubCasteList, error: errorSubCasteList}] = useGetSubCasteWithCasteIDMutation();
    useEffect(() => {
        if(isSuccessSubCasteList && dataSubCasteList){
            console.log("Sub caste master data...");
            console.log(dataSubCasteList);

            setSubCastList([...subCastList, ...getListData(dataSubCasteList.data)]);
        }
        else if(isErrorSubCasteList && errorSubCasteList){
            console.log("Sub caste master data error...");
            console.log(errorSubCasteList);

            setSubCastList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessSubCasteList, dataSubCasteList, isErrorSubCasteList, errorSubCasteList]);

    const [doshamList, setDoshamList] = useState([]);
    const {isLoading: isLoadingDosham, isSuccess: isSuccessDosham, data: dataDosham, isError: isErrorDosham, error: errorDosham} = useGetSpecificMasterDataQuery('dosha');
    useEffect(() => {
        if(isSuccessDosham && dataDosham){
            console.log("Dosham master data...");
            console.log(dataDosham);

            setDoshamList(getListData(dataDosham.data));
        }
        else if(isErrorDosham && errorDosham){
            console.log("Dosham master data error...");
            console.log(errorDosham);

            setDoshamList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessDosham, dataDosham, isErrorDosham, errorDosham]);

    const getCastIDFromName = useCallback((name) => {
        let id = "";

        for(let i = 0; i < castList.length; i++){
            if(castList[i].value === name){
                id = castList[i].id;
                break;
            }
        }

        return id;
    }, [castList]);

    const [
        getPartnerPreferenceDetails,
        {
            isLoading: isLoadingPartnerPreference, 
            isSuccess: isSuccessPartnerPreference, 
            data: dataPartnerPreference, 
            isError: isErrorPartnerPreference, 
            error: errorPartnerPreference
        }
    ]= useGetOnCallPartnerPreferenceDetailsMutation();

    const getMultiValueAssignedValues = useCallback((valuesArray) => {
        const newArray = [];

        for(let i = 0; i < valuesArray?.length; i++){
            newArray.push({label: valuesArray[i], value: valuesArray[i]});
        }
        
        return newArray;
    }, []);

    const setDefaultValues = useCallback(async (data, loadSubCasteList) => {
        setFormValue("religion", getMultiValueAssignedValues(data.religion));
        setFormValue("dosham", getMultiValueAssignedValues(data.dosha));

        if(castList.length > 0){
            setFormValue("caste", getMultiValueAssignedValues(data.caste));
            setFormValue("subCaste", getMultiValueAssignedValues(data.subCaste));

            setPrevCasteList(data.caste);
            
            if(loadSubCasteList){
                setSubCastList([]);

                if(data.caste.length > 0){
                    loadAllSubCasteList(data.caste);
                }
            }
        }
    }, [castList]);

    useEffect(() => {
        if(isSuccessPartnerPreference && dataPartnerPreference){
            console.log("Partner preference data...");
            console.log(dataPartnerPreference);

            setPartnerData(dataPartnerPreference.data);
            setDefaultValues(dataPartnerPreference.data, true);
        }
        else if(isErrorPartnerPreference && errorPartnerPreference){
            console.log("Partner preference data error...");
            console.log(errorPartnerPreference);
        }
    }, [isSuccessPartnerPreference, dataPartnerPreference]);

    const loadAllSubCasteList = useCallback(async (casteList) => {
        for(let i = 0; i < casteList.length; i++){
            await getSubCasteList({id: getCastIDFromName(casteList[i])});
        }
    }, [castList]);

    const callPartnerPreferenceDetails = useCallback(async () => {
        await getPartnerPreferenceDetails();
    }, []);

    useEffect(() => {
        callPartnerPreferenceDetails();
    }, []);

    const editCallback = useCallback((edited) => {
        if(edited){
            setNeedSubCasteValidation(true);
        }
        else{
            setDefaultValues(partnerData, false);
        }
    }, [partnerData]);

    const allowedDoshamList = useCallback(() => {
        if(formValue.dosham.length === 0){
            return [];
        }

        for(let i = 0; i < formValue.dosham.length; i++){
            if(formValue.dosham[i].value === 'None'){
                return ['None'];
            }
        }

        const array = [];

        for(let i = 0; i < doshamList.length; i++){
            if(doshamList[i].value === 'None'){
                continue;
            }

            array.push(doshamList[i].value);
        }
        
        return array;
    }, [formValue, doshamList]);

    const [
        updatePartnerPreference,
        {
            isLoading: isLoadingUpdate,
            isSuccess,
            isError,
            error
        }
    ] = useUpdatePartnerPreferenceMutation();

    useEffect(() => {
        if(isSuccess){
            callPartnerPreferenceDetails();
        }
        else if(isError && error){
            setShowSnackBar({
                state: true,
                message: `${error.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccess, isError, error]);

    const modifySubCasteList = useCallback(async () => {
        const removedCasteList = [];

        if(formValue.caste.length === 0){
            for(let i = 0; i < prevCasteList.length; i++){
                removedCasteList.push(prevCasteList[i]);
            }
        }
        else{
            for(let i = 0; i < prevCasteList.length; i++){
                for(let j = 0; j < formValue.caste.length; j++){
                    if(prevCasteList[i] === formValue.caste[j].value){
                        break;
                    }
    
                    // Not exist
                    if(j === formValue.caste.length - 1){
                        removedCasteList.push(prevCasteList[i]);
                    }
                }
            }
        }

        setSubCastList([]);
        setPrevCasteList([]);

        if(removedCasteList.length > 0){
            setShowSnackBar({
                state: true,
                message: t(`${partner_religious_locale}.re_update_sub_caste`),
                color: "blue"
            });

            const obj = {};

            const newCasteList = [];
            for(let i = 0; i < formValue.caste.length; i++){
                newCasteList.push(formValue.caste[i].value);
            }

            for (var key in partnerData) {
                if (partnerData.hasOwnProperty(key)) {
                    if(key === 'subCaste'){
                        obj[key] = [];
                    }
                    else if(key === 'caste'){
                        obj[key] = newCasteList;
                    }
                    else{
                        obj[key] = partnerData[key];
                    }
                }
            }
            
            await updatePartnerPreference(obj);
        }
        else{
            callPartnerPreferenceDetails();
        }
    }, [formValue, prevCasteList, partnerData, formValue]);

    useEffect(() => {
        if(needSubCasteValidation && formValue.caste){
            setNeedSubCasteValidation(false);
            modifySubCasteList();
        }
    }, [formValue.caste, needSubCasteValidation]);

    return (
        <>
            {
                isErrorPartnerPreference && errorPartnerPreference
                ?
                <APIErrorLayout error={errorPartnerPreference} />
                :
                <Stack
                    direction='column'
                    sx={{
                        width: {xs: '100%', lg: '60%'},
                        my: 5
                    }}
                >
                    <FormProviderComponent methods={formMethods} onSubmit={() => {}}>
                        <PartnerInfoBoxView 
                            label={t(`${partner_religious_locale}.religion`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='religion'
                                    label={t(`${partner_religious_locale}.religion`)}
                                    list={religionList}
                                />
                            }
                            editedValue={formValue.religion}
                            modifiedAttribute={["religion"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_religious_locale}.caste`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='caste'
                                    label={t(`${partner_religious_locale}.caste`)}
                                    list={castList}
                                />
                            }
                            editedValue={formValue.caste}
                            modifiedAttribute={["caste"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_religious_locale}.sub_caste`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='subCaste'
                                    label={t(`${partner_religious_locale}.sub_caste`)}
                                    list={subCastList}
                                />
                            }
                            editedValue={formValue.subCaste}
                            modifiedAttribute={["subCaste"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                            hideEditIcon={formValue.caste.length === 0 || (isSuccessSubCasteList && subCastList.length === 0)}
                            specialText={
                                formValue.caste.length === 0 
                                ? 
                                t(`${partner_religious_locale}.caste_required`)
                                : 
                                null
                            }
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_religious_locale}.dosham`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    label={t(`${partner_religious_locale}.dosham`)}
                                    name='dosham'
                                    list={doshamList}
                                    onlyAllowedValues={allowedDoshamList()}
                                />
                            }
                            editedValue={formValue.dosham}
                            modifiedAttribute={["dosha"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />
                    </FormProviderComponent>
                </Stack>
            }

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default PartnerReligiousPreferenceBody;