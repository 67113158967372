import { 
    Grid,
    Typography,
    useTheme,
    Link,
    Box,
    Stack
} from "@mui/material";

import { useTranslation } from "react-i18next";

import ButtonComponent from '../../components/form/button-component';
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback } from "react";
import { useAuth } from "../../contexts/auth/auth-provider";

const HomeReminder = () => {
    const theme = useTheme();

    const { t } = useTranslation();
    const reminder_locale = "landing_page.reminder";


    const authState = useAuth();
    let isLoggedIn = authState?.currentUser ? authState.currentUser !== null : false;


    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback(() => {
        if(!isLoggedIn){
            logCustomEvent('CLICK_START_FOR_FREE');
        }
    }, [isLoggedIn]);


    return (
        <>
            <Box sx={{px: {xs: 3, md: 7}}}>
                <Grid
                    xs
                    container
                    direction='column'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        mt: 10,
                        backgroundColor: theme.palette.primary.main,
                        py: 5,
                        borderRadius: '16px',
                        border: '4px solid rgba(255, 255, 255, .5)',
                        boxSizing: "border-box"
                    }}
                >
                    <Grid 
                        xs={12}
                    >
                        <Typography
                            textAlign='center'
                            sx={{
                                fontWeight: 600,
                                fontSize: {xs: 25, md: 32, lg: 48},
                                letterSpacing: -2,
                                color: 'white',
                                pb: 3,
                                px: {xs: 2, md: 0}
                            }}
                        >
                            {t(`${reminder_locale}.heading`)}
                        </Typography>
                    </Grid>

                    <Grid 
                        xs={12}
                    >
                        <Typography
                            textAlign='center'
                            sx={{
                                fontWeight: 400,
                                fontSize: 16,
                                lineHeight: '24px',
                                color: 'white',
                                px: 2,
                                pb: 5
                            }}
                        >
                            {t(`${reminder_locale}.caption`)}
                        </Typography>
                    </Grid>

                    <Grid 
                        xs={12}
                    >
                        <Link href={!isLoggedIn ? "#hero" : "/feed"} underline="none">
                            <ButtonComponent 
                                text={t(`${reminder_locale}.action`)}
                                onClick={() => logAnalytics()}
                                width={136}
                                sx={{
                                    color: 'black',
                                    backgroundColor: 'white',
                                    "&:hover": {
                                        backgroundColor: "#FFFFFF",
                                        transform: 'scale(1.03)'
                                    }
                                }}
                            />
                        </Link>
                    </Grid>

                    {/* Heart Illustration */}
                    <Grid
                        xs={12}
                        container
                        direction='row'
                        justifyContent='space-between'
                        position='absolute'
                        px={{xs: 1, md: 5, lg: 10}}
                        sx={{
                            transform: {xs: 'translate(0px, 70px)', md: 'translate(0px, 40px)', lg: 'translate(0px, 25px)'}
                        }}
                    >
                        <Grid 
                            xs={6}
                        >
                            <Stack>
                                <Box
                                    component="img"
                                    sx={{
                                        content: `url('/assets/icons/home-reminder-heart-1.png')`,
                                        width: {xs: 150, md: 207},
                                        position: 'absolute',
                                        zIndex: -2,
                                        animation: 'heartOpacity 1s linear infinite'
                                    }}
                                    alt="icon"
                                />

                                <Box
                                    component="img"
                                    sx={{
                                        content: `url('/assets/icons/home-reminder-heart-1.png')`,
                                        width: {xs: 90, md: 122},
                                        position: 'absolute',
                                        zIndex: -1,
                                        transform: 'translate(200px, 100px)'
                                    }}
                                    alt="icon"
                                />
                            </Stack>
                        </Grid>

                        <Grid 
                            xs={6} 
                            display='flex'
                            justifyContent='end'
                        >
                            <Box
                                component="img"
                                sx={{
                                    content: `url('/assets/icons/home-reminder-heart-2.png')`,
                                    width: {xs: 150, md: 207},
                                    position: 'absolute',
                                    zIndex: -2,
                                    animation: 'heartOpacity 1s linear infinite'
                                }}
                                alt="icon"
                            />

                            <Box
                                component="img"
                                sx={{
                                    content: `url('/assets/icons/home-reminder-heart-2.png')`,
                                    width: {xs: 90, md: 122},
                                    position: 'absolute',
                                    zIndex: -1,
                                    transform: 'translate(-200px, 100px)'
                                }}
                                alt="icon"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default HomeReminder;