import { useEffect, useState } from "react";
import ChatInterestOverviewList from "./chat-interest-overview-list";
import ChatMessageHeader from "./chat-message-header";
import ChatRequestAccept from "./chat-request-accept";
import { Alert, Box, Stack } from "@mui/material";
import { useChat } from "../../contexts/chat/chat-provider";
import { useTranslation } from "react-i18next";

const ChatInterestDetailView = () => {
    const { t } = useTranslation();
    const profile_locale = "profile";

    const [data, setData] = useState(null);

    const useChatContext = useChat();

    useEffect(() => {
        if(!data){
            const userData = useChatContext.activeInterestRequestSentUser;

            useChatContext.setChatBoxBarProperties(
                userData.firstName, 
                userData.memberId, 
                userData.profileImages > 0 ? userData.profileImages[0] : '', 
                () => {useChatContext.setChatBoxBody(<ChatInterestOverviewList />)}, 
                userData.memberId
            );

            setData(userData);
        }
    }, [useChatContext, data]);

    return (
        <>
            <Stack sx={{height: {xs: '100%', md: '60vh'}, overflow: 'scroll', px: 2}} className='hideScroll'>
                {
                    data
                    ?
                    <>
                        {
                            data.status !== 'DELETED' && data.status !== 'INACTIVE'
                            ?
                            <>
                                <ChatMessageHeader data={data}/>

                                <Box sx={{height: 100}}/>

                                <ChatRequestAccept data={data}/>
                            </>
                            :
                            <Box my={5}>
                                <Alert variant="outlined" severity="error">
                                    {data.status === 'DELETED' ? t(`${profile_locale}.alert.profile_removed`) : t(`${profile_locale}.alert.profile_inactive`)}
                                </Alert>
                            </Box>
                        }
                    </>
                    :
                    <></>
                }
            </Stack>
        </>
    );
}

export default ChatInterestDetailView;