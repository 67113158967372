import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import ProfileEditHeader from "../profile-edit-header";
import ProfileEditBody from "../profile-edit-body";

import { useTranslation } from "react-i18next";
import { Button, Divider, Stack, useTheme } from "@mui/material";

import AccountGroupIcon from "../../../assets/icons/profile-family/account-group-icon";
import BriefcaseOutlineIcon from "../../../assets/icons/briefcase-outline-icon";
import HumanFemaleFemaleIcon from "../../../assets/icons/profile-family/human-female-female-icon";
import HumanMaleMaleIcon from "../../../assets/icons/profile-family/human-male-male-icon";

import About from '../basic/about';
import { LoadingButtonComponent, SelectionComponent, TextFieldComponent } from '../../../components/form';
import DialogBox from '../../../components/Dialog/dialog-box';
import { useCallback, useState, useEffect } from 'react';
import { useGetSpecificMasterDataQuery } from '../../../services/master-data-api';
import FormProviderComponent from '../../../components/form/form-provider-component';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useUpdateProfileMutation } from '../../../services/profile-api';

const MyFamilyDetails = ({privateView, responseData, isLoading, editCallback, updateData}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_locale = "profile.family";

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [familyTypeList, setFamilyTypeList] = useState([]);
    const {isLoading: isLoadingFamilyType, isSuccess: isSuccessFamilyType, data: dataFamilyType, isError: isErrorFamilyType, error: errorFamilyType} = useGetSpecificMasterDataQuery('family-type');
    useEffect(() => {
        if(isSuccessFamilyType && dataFamilyType){
            console.log("Family type master data...");
            console.log(dataFamilyType);

            setFamilyTypeList(getListData(dataFamilyType.data));
        }
        else if(isErrorFamilyType && errorFamilyType){
            console.log("Family type master data error...");
            console.log(errorFamilyType);

            setFamilyTypeList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessFamilyType, dataFamilyType, isErrorFamilyType, errorFamilyType]);

    const formSchema = yup.object().shape({
        familyType: yup.string().notRequired(),
        mothersOccupation: yup.string().notRequired(),
        fathersOccupation: yup.string().notRequired(),
        numberOfSisters: yup.number().typeError(t(`${profile_locale}.min_number_of_sisters`)).min(0, t(`${profile_locale}.min_number_of_sisters`)),
        numberOfBrothers: yup.number().typeError(t(`${profile_locale}.min_number_of_brothers`)).min(0, t(`${profile_locale}.min_number_of_brothers`))
    });

    const defaultValues = {
        familyType: "",
        mothersOccupation: "",
        fathersOccupation: "",
        numberOfSisters: 0,
        numberOfBrothers: 0
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    const [data, setData] = useState([]);

    const setFormValuesWithData = useCallback(() => {
        console.log(">>>>>>>>>>>>>");
        console.log(responseData);

        setFormValue("familyType", responseData.familyType);
        setFormValue("mothersOccupation", responseData.motherOccupation);
        setFormValue("fathersOccupation", responseData.fatherOccupation);
        setFormValue("numberOfSisters", responseData.numberOfSisters);
        setFormValue("numberOfBrothers", responseData.numberOfBrothers);
    }, [responseData]);

    useEffect(() => {
        if(responseData){
            setData(
                [
                    {label: t(`${profile_locale}.family_type`), value: responseData.familyType, icon: <AccountGroupIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='familyType' label={t(`${profile_locale}.family_type`)} list={familyTypeList} /> },
                    {label: t(`${profile_locale}.mother_occupation`), value: responseData.motherOccupation, icon: <BriefcaseOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <TextFieldComponent name='mothersOccupation' label={t(`${profile_locale}.mother_occupation`)} /> },
                    {label: t(`${profile_locale}.father_occupation`), value: responseData.fatherOccupation, icon: <BriefcaseOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <TextFieldComponent name='fathersOccupation' label={t(`${profile_locale}.father_occupation`)} /> },
                    {label: t(`${profile_locale}.number_of_sisters`), value: responseData.numberOfSisters, icon: <HumanFemaleFemaleIcon style={{color: theme.palette.primary.main}}/>, editChildren: <TextFieldComponent name='numberOfSisters' label={t(`${profile_locale}.number_of_sisters`)}/> },
                    {label: t(`${profile_locale}.number_of_brothers`), value: responseData.numberOfBrothers, icon: <HumanMaleMaleIcon style={{color: theme.palette.primary.main}}/>, editChildren: <TextFieldComponent name='numberOfBrothers' label={t(`${profile_locale}.number_of_brothers`)}/> }
                ]
            );

            setFormValuesWithData();
        }
    }, [responseData, familyTypeList]);

    const [openEditSingleItemBox, setOpenEditSingleItemBox] = useState({
        title: '',
        children: <></>,
        open: false,
        onSuccess: () => {}
    });

    const [openEditAllBox, setOpenEditAllBox] = useState({
        title: '',
        open: false,
        onSuccess: () => {}
    });

    const openSingleEditItemModal = useCallback((data) => {
        setOpenEditSingleItemBox({
            title: data.label,
            children: data.editChildren,
            open: true,
            onSuccess: () => {}
        });
    }, []);

    const closeSingleEditItemModal = useCallback((data) => {
        setOpenEditSingleItemBox({open: false});
        setFormValuesWithData();
    }, [responseData]);

    const openEditAllModal = useCallback((heading) => {
        setOpenEditAllBox({
            title: heading,
            open: true,
            onSuccess: () => {}
        });
    }, []);

    const closeEditAllModal = useCallback((data) => {
        setOpenEditAllBox({open: false});
        setFormValuesWithData();
    }, [responseData]);

    const modifyRequestObj = useCallback((newData) => {
        const obj = {};
        
        for (var updateDataKey in updateData) {
            if (updateData.hasOwnProperty(updateDataKey)) {
                console.log(updateDataKey + " -----> NOT MODIFIED ----->  " + updateData[updateDataKey]);
                obj[updateDataKey] = updateData[updateDataKey];

                for (var newDataKey in newData){
                    if (newData.hasOwnProperty(newDataKey)){
                        if(updateDataKey !== newDataKey){
                            continue;
                        }
                    
                        if(updateData[updateDataKey] !== newData[newDataKey]){
                            console.log(newDataKey + " -----> MODIFIED ----->  " + newData[newDataKey]);
                            obj[updateDataKey] = newData[newDataKey];
                        }
                    }
                }
            }
        }

        return obj;
    }, [updateData]);

    const [
        updateProfile, 
        {
          isLoading: isLoadingUpdateProfile,
          isSuccess: isSuccessUpdateProfile,
          isError: isErrorUpdateProfile,
          error: errorUpdateProfile
        }
    ] = useUpdateProfileMutation();

    useEffect(() => {
        if (isSuccessUpdateProfile){
            setOpenEditAllBox({open: false});
            setOpenEditSingleItemBox({open: false});

            editCallback();
        }
        else if (isErrorUpdateProfile && errorUpdateProfile) {
            setShowSnackBar({
                state: true,
                message: `${errorUpdateProfile.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessUpdateProfile, isErrorUpdateProfile, errorUpdateProfile, editCallback]);

    const onSubmit = handleSubmit(async(data) => {
        console.log(data);
        
        const requestData = modifyRequestObj(data);
        console.log(requestData);

        await updateProfile(requestData);
    });

    return (
        <>
            <About 
                heading={t(`${profile_locale}.about_my_family`)} 
                value={responseData?.aboutFamily} 
                privateView={privateView}
                updateData={updateData}
                mappingValue='aboutFamily'
                isLoading={isLoading}
                editCallback={editCallback}
            />

            <ProfileEditHeader 
                heading={t(`${profile_locale}.my_family_details`)} 
                privateView={privateView}
                openEditAllCallback={openEditAllModal}
            />

            <ProfileEditBody 
                dataList={data} 
                privateView={privateView} 
                callback={openSingleEditItemModal}
                isLoading={isLoading}
            />

            <DialogBox 
                title={openEditSingleItemBox.title}
                children={
                    <FormProviderComponent methods={formMethods} onSubmit={onSubmit}>
                        {openEditSingleItemBox.children}

                        <Divider sx={{mt: 8, mb: 2}}/>

                        <Stack 
                            direction={{xs: 'column-reverse', md: 'row'}} 
                            spacing={2} 
                            sx={{width: '100%'}}
                            justifyContent='end'
                        >
                            <Button 
                                variant="outlined" 
                                color='primary' 
                                size="large"
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                    "&:hover": {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={closeSingleEditItemModal}
                            >
                                {t(`dialog.cancel`)}
                            </Button>
                            
                            <LoadingButtonComponent 
                                text={t(`profile.save`)}
                                type="submit"
                                inLoading={isLoadingUpdateProfile}
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                }}
                            />
                        </Stack>
                    </FormProviderComponent>
                }
                open={openEditSingleItemBox.open} 
                onClose={closeSingleEditItemModal}
                customFooter
            />

            <DialogBox 
                title={openEditAllBox.title}
                children={
                    <FormProviderComponent methods={formMethods} onSubmit={onSubmit}>
                        <Stack
                            direction='column'
                            spacing={2}
                        >
                            <SelectionComponent name='familyType' label={t(`${profile_locale}.family_type`)} list={familyTypeList} />

                            <TextFieldComponent name='mothersOccupation' label={t(`${profile_locale}.mother_occupation`)} />

                            <TextFieldComponent name='fathersOccupation' label={t(`${profile_locale}.father_occupation`)} />

                            <TextFieldComponent name='numberOfSisters' label={t(`${profile_locale}.number_of_sisters`)}/>

                            <TextFieldComponent name='numberOfBrothers' label={t(`${profile_locale}.number_of_brothers`)}/>
                        </Stack>

                        <Divider sx={{mt: 8, mb: 2}}/>

                        <Stack 
                            direction={{xs: 'column-reverse', md: 'row'}} 
                            spacing={2} 
                            sx={{width: '100%'}}
                            justifyContent='end'
                        >
                            <Button 
                                variant="outlined" 
                                color='primary' 
                                size="large"
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                    "&:hover": {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={closeEditAllModal}
                            >
                                {t(`dialog.cancel`)}
                            </Button>
                            
                            <LoadingButtonComponent 
                                text={t(`profile.save`)}
                                type="submit"
                                inLoading={isLoadingUpdateProfile}
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                }}
                            />
                        </Stack>
                    </FormProviderComponent>
                }
                open={openEditAllBox.open} 
                onClose={closeEditAllModal}
                customFooter
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default MyFamilyDetails;

MyFamilyDetails.propType = {
    privateView: PropTypes.bool,
    responseData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func,
    updateData: PropTypes.object
}