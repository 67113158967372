import PropTypes from 'prop-types';

import { 
    Box,
    IconButton,
    Menu,
    Stack,
    Typography,
    Avatar,
    Divider,
    Tooltip,
    useTheme
} from "@mui/material";

import CloseIcon from '../../assets/icons/close-icon';
import ButtonComponent from '../../components/form/button-component';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useGetAllNotificationsMutation, useReadNotificationsMutation } from '../../services/notification-api';
import SkeletonComponent from '../../components/loading/skeleton-component';
import APIErrorLayout from '../error/api-error/api-error-layout';
import EmptyLayout from '../error/empty/empty-layout';
import { useNavigate } from 'react-router-dom';
import { useGetUserInboxDetailsMutation } from '../../services/chat-api';
import { useChat } from '../../contexts/chat/chat-provider';
import ChatPersonal from '../chat/chat-personal';
import SnackbarComponent from '../../components/snack-bar/snack-bar-component';
import LoadingButtonComponent from '../../components/form/loading-button-component';
import ChatAdmin from '../chat/chat-admin';
import ChatBoxTabLayout from '../chat/chat-box-tab-layout';
import { useAuth } from '../../contexts/auth/auth-provider';

const NotificationPanel = ({anchor, open, handleClose, clearNotificationCount}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const notification_locale = "notification";

    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [notificationList, setNotificationList] = useState([]);

    const navigate = useNavigate();

    const useChatContext = useChat();
    const authState = useAuth();

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [
        getNotifications,
        {isLoading, isSuccess, data, isError, error}
    ] = useGetAllNotificationsMutation();

    const [
        readNotifications,
        {isSuccess: isSuccessReadNotification, isError: isErrorReadNotification, error: errorReadNotification}
    ] = useReadNotificationsMutation();

    useEffect(() => {
        if(isSuccess && data){
            console.log("Notification data...");
            console.log(data);

            setNotificationList([...notificationList, ...data.data.content]);
            setTotalPages(data.data.page.totalPages);

            if(activePage === 0) readNotifications();
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if(isSuccessReadNotification){
            console.log("Notification read success...");
            clearNotificationCount();
        }
        else if(isErrorReadNotification && errorReadNotification){
            console.log("Notification read error...");
            console.log(errorReadNotification);
        }
    }, [isSuccessReadNotification, isErrorReadNotification, errorReadNotification]);

    const [
        getUserInboxDetails,
        {
            isLoading: isLoadingAllDirectChats,
            isSuccess: isSuccessAllDirectChats,
            data: dataAllDirectChats,
            isError: isErrorAllDirectChats,
            error: errorAllDirectChats
        }
    ] = useGetUserInboxDetailsMutation();

    useEffect(() => {
        if (isSuccessAllDirectChats) {
            console.log("user chat inbox data...");
            console.log(dataAllDirectChats); 

            useChatContext.setActiveUserChat(dataAllDirectChats.data);
            useChatContext.setChatBoxBody(<ChatPersonal />);

            if(window.innerWidth < 800){
                navigate('/chat');
            }
            else{
                useChatContext.openChatBox(true);
            }
        }
    }, [isSuccessAllDirectChats, dataAllDirectChats]);

    useEffect(() => {
        if(isErrorAllDirectChats && errorAllDirectChats){
            console.log(errorAllDirectChats);

            setShowSnackBar({
                state: true,
                message: `${errorAllDirectChats.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorAllDirectChats, errorAllDirectChats]);

    useEffect(() => {
        if(open){
            setActivePage(0);
        }
        else{
            setNotificationList([]);
        }
    }, [open]);

    useEffect(() => {
        if(open){
            getNotifications(activePage);
        }
    }, [activePage, open]);

    const capitalizeFirstLetter = useCallback((value) => {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }, []);

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;

        if (bottom) {
            let page = activePage;
            page += 1;
            page = page > totalPages ? totalPages : page;

            setActivePage(page);
        }
    }

    const isNeedAction = useCallback((module) => {
        if(module === "/interest/received" || module === "/message/received" || module === "/interest/accepted"){
            return true;
        }

        return false;
    }, []);

    const actionLabel = useCallback((module) => {
        if(module === "/interest/received"){
            return t(`${notification_locale}.view_request`);
        }
        else if(module === "/message/received"){
            return t(`${notification_locale}.chat`);
        }
        else if(module === "/interest/accepted"){
            return t(`${notification_locale}.view_profile`);
        }
    }, []);

    const actionPerform = useCallback((module, data) => {
        handleClose();

        if(module === "/interest/received"){
            navigate("/interests");
        }
        else if(module === "/message/received"){
            useChatContext.closeChatBox();
            useChatContext.setChatBoxBody(null);

            if(data.id === "admin"){

                if(window.innerWidth < 800){
                    navigate('/chat');
                }
                else{
                    useChatContext.setChatTabIndex(1);
                    useChatContext.setChatBoxBody(<ChatBoxTabLayout />);
                    useChatContext.openChatBox(true);
                }
            }
            else{
                getUserInboxDetails({userID: data.id});
            }
        }
        else if(module === "/interest/accepted"){
            navigate(`/profile/${data.memberId}`);
        }
    }, []);

    const getProfilePic = useCallback((item) => {
        // Show my photo
        if(item.module === "/id/verification"){
            if(authState?.currentUser?.profile){
                return authState?.currentUser?.profile;
            }
            else if(authState?.currentUser?.gender === 'MALE'){
                return '/assets/images/profile/profile_male.jpg';
            }
            else{
                return '/assets/images/profile/profile_female.jpg';
            }
        }
        // Others' photo
        else if(item.additionalData?.image && item.additionalData.image.length > 0){
            return item.additionalData.image[0];
        }
        else if(authState?.currentUser?.gender !== 'MALE') {
            return '/assets/images/profile/profile_male.jpg';
        }
        else{
            return '/assets/images/profile/profile_female.jpg';
        }
    }, [authState]);

    const getNotificationBody = () => <>
        {
            notificationList.length > 0
            ?
            notificationList.map((item, index) => {
                return <div key={index} style={{width: '100%'}}>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='start'
                        spacing={2}
                        py={2}
                        pl={2}
                    >
                        <Avatar 
                            alt="Avatar"
                            src={getProfilePic(item)}
                            sx={{ width: '75px', height: '75px' }}
                        />

                        <Stack
                            direction='column'
                            alignItems='start'
                            justifyContent='space-between'
                            spacing={1}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '14px'
                                }}
                            >
                                {item.additionalData?.name ? capitalizeFirstLetter(item.additionalData?.name) : 'Unknown sender'}
                            </Typography>

                            <Tooltip title={item.content}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '12px'
                                    }}
                                >
                                    {item.content.length > 35 ? `${item.content.substring(0, 32)}...` : item.content}
                                </Typography>
                            </Tooltip>

                            {
                                isNeedAction(item.module) // "/interest/received" or "/message/received" or "/id/verification" or "/interest/accepted"
                                ?
                                <LoadingButtonComponent 
                                    text={actionLabel(item.module)}
                                    onClick={() => actionPerform(item.module, item.additionalData)}
                                    inLoading={isLoadingAllDirectChats}
                                    sx={{
                                        backgroundColor: 'rgba(251, 57, 87, 0.1)',
                                        width: '150px',
                                        height: '45px',
                                        boxShadow: 'none',
                                        '&:hover' : {
                                            backgroundColor: 'rgba(251, 57, 87, 0.1)',
                                            boxShadow: 'none',
                                        },
                                    }}
                                    textSx={{
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        color: theme.palette.primary.main,
                                        p: 0,
                                        textTransform: 'capitalize'
                                    }}
                                />
                                :
                                <></>
                            }
                        </Stack>
                    </Stack>

                    <Divider style={{
                        width:'100%', 
                        backgroundColor: 'rgba(217, 217, 217, 0.01)',
                        borderBottomWidth: 2,
                        opacity: 0.25
                    }} />
                </div>
            })
            :
            <EmptyLayout />
        }
    </>

    let body;

    if(isLoading){
        notificationList.length > 0
        ?
        body = getNotificationBody()
        :
        body = <Stack direction='column' spacing={2} my={1} alignItems='center' sx={{width: '100%'}}>
            <SkeletonComponent variant='rounded' width='350px' height='75px'/>
            <SkeletonComponent variant='rounded' width='350px' height='75px'/>
            <SkeletonComponent variant='rounded' width='350px' height='75px'/>
            <SkeletonComponent variant='rounded' width='350px' height='75px'/>
        </Stack>
    } 
    else if (isError) {
        console.log(error);
        body = <APIErrorLayout error={error}/>
    }
    else if(isSuccess){
        body = getNotificationBody()
    }

    return(
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        onScroll: handleScroll
                    }
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: { p: 0 }
                }}
            >
                <Stack 
                    direction='column'
                    sx={{width: '400px'}}
                >
                    <Box
                        py={1.5}
                        px={2}
                        sx={{
                            backgroundColor: '#F6F6F6',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                        }}
                    >
                        <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '16px'
                                }}
                            >
                                {t(`${notification_locale}.heading`)}
                            </Typography>

                            <IconButton aria-label="close" size="medium" sx={{backgroundColor: 'white'}} onClick={handleClose}>
                                <CloseIcon style={{width: 15, height: 15, color: 'black'}}/>
                            </IconButton>
                        </Stack>
                    </Box>

                    <Stack 
                        direction='column' 
                        alignItems='start'
                        py={2}
                        sx={{
                            height: '400px'
                        }}
                    >
                        {body}
                    </Stack>
                </Stack>
            </Menu>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />   
        </>
    );
}

export default NotificationPanel;

NotificationPanel.propType = {
    anchor: PropTypes.object, 
    open: PropTypes.bool, 
    handleClose: PropTypes.func,
    clearNotificationCount: PropTypes.func
}