import { 
    Grid, 
    Stack,
    Link,
    Typography,
    Divider
} from "@mui/material";

import FacebookIcon from "../../assets/icons/facebook-icon";
import InstagramIcon from "../../assets/icons/instagram-icon";
import { useTranslation } from "react-i18next";

import ButtonComponent from "../../components/form/button-component";

import { useAuth } from "../../contexts/auth/auth-provider";
import EmailIcon from "../../assets/icons/email-icon";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback } from "react";

const Footer = () => {
    const { t } = useTranslation();
    const footer_locale = "landing_page.footer";
    const navigation_locale = "navigation";

    const authState = useAuth();
    let isLoggedIn = authState?.currentUser ? authState.currentUser !== null : false;

    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const getLinks = (name, link) => 
    <Link 
        href={link} 
        underline="none" 
        sx={{
            '&: hover': {
                transform: 'scale(1.05)'
            }
        }}
    >
        <Typography
            sx={{
                fontWeight: 400,
                fontSize: 14,
                color: 'white'
            }}
        >
            {name}
        </Typography>
    </Link>

    return (
        <>
            <Grid
                xs
                container
                display='flex'
                direction='column'
                sx={{
                    backgroundColor: 'black',
                    p: {xs: 3, md: 7}
                }}
            >
                <Grid
                    xs={12}
                    container
                    direction='row'
                    justifyContent={{xs: 'center', md: 'start'}}
                >
                    <Grid
                        xs={12} md={8}
                        direction='column'
                    >
                        <Stack direction='row' spacing={2} justifyContent={{xs: 'center', md: 'start'}}>
                            <Link 
                                href="https://www.facebook.com/share/Y18VNUVcuBRz9T1y" 
                                target="_blank"
                                onClick={() => logAnalytics('CLICK_FACEBOOK')}
                                sx={{
                                    '&: hover': {
                                        transform: 'scale(1.25)'
                                    }
                                }}
                            >
                                <FacebookIcon 
                                    style={{
                                        color: 'white',
                                        width: '30px',
                                        height: '30px'  
                                    }}
                                />
                            </Link>

                            <Link 
                                href="https://www.instagram.com/eelam.maangalyam" 
                                target="_blank"
                                onClick={() => logAnalytics('CLICK_INSTAGRAM')}
                                sx={{
                                    '&: hover': {
                                        transform: 'scale(1.25)'
                                    }
                                }}
                            >
                                <InstagramIcon 
                                    style={{
                                        color: 'white',
                                        width: '30px',
                                        height: '30px'
                                    }}
                                />
                            </Link>

                            <Link 
                                href="mailto:support@eelammaangalyam.com" 
                                target="_blank"
                                onClick={() => logAnalytics('CLICK_MAIL')}
                                sx={{
                                    '&: hover': {
                                        transform: 'scale(1.25)'
                                    }
                                }}
                            >
                                <EmailIcon 
                                    style={{
                                        color: 'white',
                                        width: '30px',
                                        height: '30px' 
                                    }}
                                />
                            </Link>

                            {/* <Link href="https://facebook.com" target="_blank">
                                <TwitterIcon 
                                    style={{
                                        color: 'white',
                                        width: '25px',
                                        height: '25px',
                                        marginTop: '3px'
                                    }}
                                />
                            </Link>

                            <Link href="https://facebook.com" target="_blank">
                                <LinkedinIcon 
                                    style={{
                                        color: 'white',
                                        width: '30px',
                                        height: '30px'
                                    }}
                                />
                            </Link>

                            <Link href="https://facebook.com" target="_blank">
                                <YoutubeIcon 
                                    style={{
                                        color: 'white',
                                        width: '30px',
                                        height: '30px'
                                    }}
                                />
                            </Link> */}
                        </Stack>

                        <Stack direction='row' sx={{pt: 4}} justifyContent={{xs: 'center', md: 'start'}}>
                            <Stack spacing={1}>
                                {isLoggedIn ? getLinks(t(`${navigation_locale}.feed`), `/feed`) : <></>}

                                {getLinks(t(`${footer_locale}.home`), '/')}

                                {getLinks(t(`${footer_locale}.about`), '/about-us')}

                                {getLinks(t(`${footer_locale}.contact`), '/contact-us')}

                                {!isLoggedIn ? getLinks(t(`${footer_locale}.login`), '/login'): <></>}

                                {getLinks(t(`${footer_locale}.signup`), '/#hero')}
                            </Stack>

                            <Stack spacing={1} sx={{pl: 12}}>
                                {getLinks(t(`${footer_locale}.privacy`), '/privacy-policy')}

                                {getLinks(t(`${footer_locale}.terms`), '/terms-and-conditions')}

                                {getLinks(t(`${footer_locale}.report`), 'mailto:support@eelammaangalyam.com')}
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid
                        display='flex'
                        direction='column'
                        xs={12} md={4}
                        alignItems={{xs: 'center', md: 'start'}}
                    >
                        <Stack 
                            sx={{
                                width: {xs: '80%', sm: '50%', lg: '80%'},
                                pt: {xs: 4, md: 0},
                                pb: 2
                            }}
                        >
                            <img 
                                src='/assets/images/logo/white-logo.png' 
                                alt="logo"
                            />
                        </Stack>

                        <Link href={!isLoggedIn ? "#hero" : "/feed"} underline="none">
                            <ButtonComponent 
                                text={t(`${footer_locale}.action`)}
                                onClick={() => !isLoggedIn ? logAnalytics('CLICK_START_FOR_FREE') : null}
                                width={100}
                                sx={{
                                    color: 'black',
                                    backgroundColor: 'white',
                                    "&:hover": {
                                        backgroundColor: "#FFFFFF",
                                        transform: 'scale(1.03)'
                                    },
                                    ml: {xs: 0, md: 3}
                                }}
                            />
                        </Link>
                    </Grid>
                </Grid>

                <Divider sx={{backgroundColor: '#6D6D6D', my: 5}}/>

                <Grid
                    xs={12}
                    direction='row'
                    container
                    justifyContent={{xs: 'center', md: 'space-between'}}
                >
                    <Grid
                        xs={12} md={6}
                        justifyContent={{xs: 'center', md: 'start'}}
                    >
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: 'white',
                                textAlign: {xs: 'center', md: 'left'}
                            }}
                        >
                            <span>&copy;</span> {`${t(`${footer_locale}.rights`)}`}
                        </Typography>
                    </Grid>

                    <Grid
                        xs={12} md={6}
                        display='flex'
                        justifyContent={{xs: 'center', md: 'end'}}
                        pt={{xs: 1, md: 0}}
                        pb={7}
                    >
                        <a href="https://wa.link/tvivck" target="_blank" style={{textDecoration: 'none'}}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 12,
                                    color: 'white',
                                    '&: hover': {
                                        transform: 'scale(1.05)'
                                    }
                                }}
                            >
                                v{process.env.REACT_APP_APP_VERSION} | {t(`${footer_locale}.techserw`)}
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Footer;