import PropTypes from 'prop-types';

import {
    Grid, 
    Typography,
    Chip,
    Stack,
    Button,
    useTheme,
    Container,
    Box
} from '@mui/material';

import { useCallback, useEffect, useRef, useState } from 'react';

import DialogBox from '../Dialog/dialog-box';
import HoroscopePlanetForm from './horoscope-planet-form';

import PlusIcon from '../../assets/icons/plus-icon';
import EditIcon from '../../assets/icons/edit-icon';
import CloseIcon from '../../assets/icons/clear-icon';
import { useTranslation } from 'react-i18next';

const planetList = [
    {id: 0, label: 'Sooryan', value: 'Sooryan', tamilLabel: 'சூரியன்'},
    {id: 1, label: 'Chandran', value: 'Chandran', tamilLabel: 'சந்திரன்'},
    {id: 2, label: 'Sevvai', value: 'Sevvai', tamilLabel: 'செவ்வாய்'},
    {id: 3, label: 'Pudhan', value: 'Pudhan', tamilLabel: 'புதன்'},
    {id: 4, label: 'Guru', value: 'Guru', tamilLabel: 'குரு'},
    {id: 5, label: 'Sukkiran', value: 'Sukkiran', tamilLabel: 'சுக்கிரன்'},
    {id: 6, label: 'Sani', value: 'Sani', tamilLabel: 'சனி'},
    {id: 7, label: 'Raagu', value: 'Raagu', tamilLabel: 'ராகு'},
    {id: 8, label: 'Kedhu', value: 'Kedhu', tamilLabel: 'கேது'}
]

const HoroscopeGrid = ({horoscopeInfo, setHoroscopeList, clearHoroscopeList, view = false, width='100%', title}) => {
    const maxGridHeight = 198;
    const borderSize = 2;

    const theme = useTheme();

    const { t } = useTranslation();
    const signup_locale = "authentication.sign_up.step3.form";

    const [onViewLagnaForm, setOnViewLagnaForm] = useState({open: false, id: 0});
    const [onViewResetForm, setOnViewResetForm] = useState(false);
    const [onViewPlanetForm, setOnViewPlanetForm] = useState(false);
    const [selectedGridInfo, setSelectedGridInfo] = useState({});

    const [lagnamID, setLagnamID] = useState(-1);
    const [gridID, setGridID] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    const [gridInfo, setGridInfo] = useState([[], [], [], [], [], [], [], [], [], [], [], []]);
    const [unavailablePlanets, setUnavailablePlanets] = useState([]);

    const getPlanetListItem = (value) => {
        let item;

        for(let i = 0; i < planetList.length; i++){
            if(planetList[i].value === value){
                item = planetList[i];
                break;
            }
        }

        return item;
    }

    const updateGridIDArray = useCallback((lagnaID) => {
        const array = [...gridID];
        let index = lagnaID;
    
        for(let i = 0; i < array.length; i++){
            array[index] = i + 1;
    
            index++;
            index = index > array.length - 1 ? 0 : index;
        }

        setGridID(array);
    }, []);

    const updateGridInfoArray = useCallback(() => {
        if (horoscopeInfo && horoscopeInfo.length > 0){
            const array = [...gridInfo];
            const unAvailableArray = [];

            console.log(horoscopeInfo);

            for(let i = 0; i < horoscopeInfo.length; i++){
                array[i] = [];

                for(let j = 0; j < horoscopeInfo[i].planets.length; j++){
                    const planet = getPlanetListItem(horoscopeInfo[i].planets[j]);

                    array[i].push(planet);
                    unAvailableArray.push(planet);
                }
            }

            console.log(array);

            setGridInfo(array);
            setUnavailablePlanets(unAvailableArray);
        }
    }, [horoscopeInfo]);

    useEffect(() => {
        if(lagnamID >= 0){
            const array = [...gridID];
            let index = lagnamID;
    
            for(let i = 0; i < array.length; i++){
                array[index] = i + 1;
    
                index++;
                index = index > array.length - 1 ? 0 : index;
            }

            setGridID(array);
            updateGridIDArray(lagnamID);
            updateGridInfoArray();
        }
    }, [lagnamID]);

    useEffect(() => {
        if (horoscopeInfo && horoscopeInfo.length > 0){
            const array = [...gridID];

            for(let i = 0; i < horoscopeInfo.length; i++){
                array[i] = horoscopeInfo[i].id;

                if(horoscopeInfo[i].id === 1){
                    setLagnamID(i);
                }
            }

            setGridID(array);
        }
    }, [horoscopeInfo]);

    const getTamilPlanetName = useCallback((value, needTamilName) => {
        let label;

        for(let i = 0; i < planetList.length; i++){
            if(planetList[i].value === value){
                label = needTamilName ? `${planetList[i].label} (${planetList[i].tamilLabel})` : planetList[i].label;
                break;
            }
        }

        return label;
    }, [planetList]);

    const handleOnClearConfirmation = useCallback(() => {
        setOnViewResetForm(true);
    },[]);

    const handleOnClear = useCallback(() => {
        setLagnamID(-1);
        setSelectedGridInfo({});
        setGridInfo([[], [], [], [], [], [], [], [], [], [], [], []]);
        setUnavailablePlanets([]);

        setOnViewResetForm(false);

        if(clearHoroscopeList) clearHoroscopeList();
    },[]);

    const handleCloseClearForm = useCallback(() => {
        setOnViewResetForm(false);
    },[]);

    const handleOnViewLagnaForm = useCallback((boxCount) => {
        setOnViewLagnaForm({
            open: true, 
            id: boxCount
        });
    },[]);

    const handleCloseLagnaForm = useCallback(() => {
        setOnViewLagnaForm({
            open: false, 
            id: 0
        });
    },[]);

    const handleSelectLagna = useCallback(() => {
        const newLagnaID = onViewLagnaForm.id;
        setLagnamID(newLagnaID);

        handleCloseLagnaForm();
    },[onViewLagnaForm]);

    const handleOnViewPlanetForm = useCallback((boxCount) => {
        setSelectedGridInfo({id: boxCount, data: gridInfo[boxCount]});
    },[gridInfo]);

    useEffect(() => {
        if(selectedGridInfo.id !== undefined){
            setOnViewPlanetForm(true);
        }
    }, [selectedGridInfo]);

    const handleClosePlanetForm = useCallback(() => {
        setOnViewPlanetForm(false);
    },[]);

    const handleSelectPlanet = useCallback((gridOldInfo, data) => {
        let unAvailableArray = [...unavailablePlanets];
        console.log("Unavailable...");
        console.log(unavailablePlanets);

        const newlyAddedItem = [];
        for(let i = 0; i < data.length; i++){
            if(!gridOldInfo.data.includes(data[i])){
                newlyAddedItem.push(data[i]);
            }
        }

        const removedItem = [];
        for(let i = 0; i < gridOldInfo.data.length; i++){
            if(!data.includes(gridOldInfo.data[i])){
                removedItem.push(gridOldInfo.data[i]);
            }
        }
 
        for(let i = 0; i < removedItem.length; i++){
            unAvailableArray = unAvailableArray.filter(value => value !== removedItem[i]);
        }

        for(let i = 0; i < newlyAddedItem.length; i++){
            if(!unAvailableArray.includes(newlyAddedItem[i])){
                unAvailableArray.push(newlyAddedItem[i]);
            }
        }

        setUnavailablePlanets(unAvailableArray);

        const gridArray = [...gridInfo];
        gridArray[gridOldInfo.id] = data;

        console.log(gridArray);

        setGridInfo(gridArray);

        if(setHoroscopeList){
            setHoroscopeList(lagnamID, gridArray);
        }

        setOnViewPlanetForm(false);
    },[unavailablePlanets, gridInfo, lagnamID]);

    const gridInstruction = (instruction, onClick, mt=0) => <>
        <Button 
            size="large"
            style={{ backgroundColor: 'transparent' }} 
            disableRipple
            onClick={onClick}
            sx={{
                mt: mt, 
                width: '100%'
            }}
        >
            <Stack 
                display='flex' 
                justifyContent='center' 
                alignItems='center'
            >
                <PlusIcon style={{marginBottom: 1, color: '#000000'}}/>

                <Typography 
                    style={{
                        fontWeight: 600, 
                        fontSize: {xs: 12, md: 14}, 
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        color: '#000000'
                    }}
                >
                    {instruction}
                </Typography>
            </Stack>
        </Button>
    </>

    const getGridIDInTwoDigits = (id) => id < 10 ? `0${id}` : id;

    const getGridPlanets = (item, index) => <Container 
        key={index}
        sx={{
            backgroundColor: item === 'Chandran' ? theme.palette.primary.main: 'transparent',
            borderRadius: '3px',
            mb: 0.5,
            height: 20,
            width: '100%'//{xs: '100%', md: 'fit-content'}
        }}>
            <Box display={{xs: 'none', md: 'block'}}>
                <Typography
                    fontFamily='Inter'
                    fontWeight={600}
                    fontSize={12}
                    textAlign='center'
                    color={item === 'Chandran' ? 'white': '#121212'}
                >
                    {getTamilPlanetName(item, true)}
                </Typography>
            </Box>

            <Box display={{xs: 'flex', md: 'none'}}>
                <Typography
                    fontFamily='Inter'
                    fontWeight={600}
                    fontSize={12}
                    textAlign='center'
                    color={item === 'Chandran' ? 'white': '#121212'}
                >
                    {getTamilPlanetName(item, false)}
                </Typography>
            </Box>
    </Container>

    const getGridBody = (boxCount) => <>
        <Grid 
            xs
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                border: `${boxCount === lagnamID ? '2px': '5px'} solid ${boxCount === lagnamID ? theme.palette.primary.main: 'white'}`,
                backgroundColor: '#E7E7E7', 
                borderRadius: borderSize,
                height: maxGridHeight + borderSize
            }}
        >
            {
                !view && lagnamID === -1
                ?
                gridInstruction(t(`${signup_locale}.start_your_lagna`), () => handleOnViewLagnaForm(boxCount))
                :
                <>
                    <Grid 
                        xs
                        container
                        direction='column'
                        sx={{height: maxGridHeight + borderSize, p: 0}}
                    >
                        <Grid xs display='flex' container direction='row' justifyContent='space-between' sx={{p: 1}}>
                            <Grid xs={6} sx={{display: {xs: 'none', md: 'block'}}}>
                                <Chip 
                                    label={
                                        boxCount !== lagnamID 
                                        ? 
                                        getGridIDInTwoDigits(gridID[boxCount]) 
                                        : 
                                        `${getGridIDInTwoDigits(gridID[boxCount])} - ${t(`${signup_locale}.laknam`)}`
                                    }
                                    sx={{
                                        backgroundColor: boxCount === lagnamID ? theme.palette.primary.main : 'white',
                                        color: boxCount === lagnamID ? 'white' : 'black',
                                        borderRadius: boxCount === lagnamID ? '25px' : '50%',
                                        fontSize: {xs: 8, md: 12},
                                        fontWeight: 600, 
                                        "&:hover": {
                                            backgroundColor: boxCount === lagnamID ? theme.palette.primary.main : 'white',
                                        }
                                    }}
                                />
                            </Grid>

                            {
                                !view && gridInfo[boxCount].length > 0
                                ?
                                <Grid xs={12} md={6} sx={{display:'flex', justifyContent:'flex-end', alignItems: 'flex-start'}}>
                                    <Button sx={{my: 0, py: 0, mr: -1}} disableRipple onClick={() => handleOnViewPlanetForm(boxCount)}>
                                        <Chip 
                                            label={<EditIcon style={{color:'white'}}/>}
                                            sx={{
                                                backgroundColor: 'black',
                                                borderRadius: '25px',
                                                pt: 0.5,
                                                "&:hover": {
                                                    backgroundColor: 'black',
                                                }
                                            }}
                                        >
                                            <EditIcon />
                                        </Chip>
                                    </Button>
                                </Grid>
                                :
                                <></>
                            }
                        </Grid>

                        <Grid xs container sx={{mt: -10, mb: 1}}>
                            {
                                gridInfo[boxCount].length > 0
                                ?
                                <>
                                    {
                                        gridInfo[boxCount].map((item, index) => {
                                            return getGridPlanets(item.value, index)
                                        })
                                    }
                                </>
                                :
                                <>
                                    {
                                        view || unavailablePlanets.length === planetList.length 
                                        ?
                                        <></>
                                        :
                                        gridInstruction(t(`${signup_locale}.add_your_planet_options`), () => handleOnViewPlanetForm(boxCount), -6)
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                </>
            }
        </Grid>
    </>

    return (
        <>
            <Grid
                container
                sx={{width: {xs: '100%', md: width}, my: 1}}
            >
                {
                    [0, 1, 2, 3].map((item, index) => {
                        return <Grid 
                            container
                            xs={3} 
                            key={index}
                        >
                            {getGridBody(item)}
                        </Grid>
                    })
                }

                <Grid 
                    xs={12}
                    display='flex'
                    justifyContent='center'
                    alignContent='center'
                    sx={{height: 2 * (maxGridHeight + borderSize)}}
                >
                    <Grid 
                        xs={3} 
                        direction='column'
                    >
                        <Grid 
                            xs 
                            container
                        >
                            {getGridBody(11)}
                        </Grid>

                        <Grid 
                            xs 
                            container
                        >
                            {getGridBody(10)}
                        </Grid>
                    </Grid>

                    <Grid 
                        xs={6} 
                        sx={{
                            border: '5px solid white',
                            backgroundColor: '#E7E7E7', 
                            borderRadius: borderSize, 
                            height: 2 * (maxGridHeight + borderSize),
                        }}
                        display='flex'
                        direction='column'
                    >
                        <Grid 
                            xs
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems={lagnamID === -1 || view ? "center" : "flex-end"}
                        >
                            <Typography 
                                textAlign='center'
                                sx={{
                                    fontWeight: 600,
                                    fontSize: {xs: 24, md: 34, lg: 48},
                                    color: 'black',
                                    letterSpacing: -2
                                }}
                            >
                                {title}
                            </Typography>
                        </Grid>

                        {
                            lagnamID === -1 || view
                            ?
                            <></>
                            :
                            <Grid 
                                xs
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Button 
                                    variant="text" 
                                    startIcon={<CloseIcon style={{color: '#4F4F4F'}} />}
                                    sx={{color: '#4F4F4F'}}
                                    onClick={handleOnClearConfirmation}
                                >
                                    <Typography 
                                        textAlign='center'
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: {xs: 12, md: 16},
                                            color: '#4F4F4F',
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        <u>{t(`${signup_locale}.clear_fields`)}</u>
                                    </Typography>
                                </Button>
                            </Grid>
                        }
                    </Grid>

                    <Grid 
                        xs={3} 
                        direction='column'
                    >
                        <Grid 
                            xs
                            container
                        >
                            {getGridBody(4)}
                        </Grid>

                        <Grid 
                            xs 
                            container
                        >
                            {getGridBody(5)}
                        </Grid>
                    </Grid>
                </Grid>

                {
                    [9, 8, 7, 6].map((item, index) => {
                        return <Grid 
                            xs={3} 
                            container
                            key={index}
                        >
                            {getGridBody(item)}
                        </Grid>
                    })
                }
            </Grid>

            <DialogBox 
                title={t(`${signup_locale}.lagna_selection_confirmation_title`)}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {t(`${signup_locale}.lagna_selection_confirmation_body`)}
                    </Typography>
                }
                open={onViewLagnaForm.open} 
                onClose={handleCloseLagnaForm}
                onSuccess={handleSelectLagna}
            />

            <DialogBox 
                children={
                    <HoroscopePlanetForm 
                        gridInfo={selectedGridInfo}
                        planetList={planetList}
                        unavailablePlanets={unavailablePlanets}
                        onSelect={handleSelectPlanet}
                    />
                }
                title={t(`${signup_locale}.horoscope_planet_form_title`)}
                subTitle={t(`${signup_locale}.horoscope_planet_form_subtitle`)}
                open={onViewPlanetForm} 
                onClose={handleClosePlanetForm}
                customFooter={true}
            />

            <DialogBox 
                title={t(`${signup_locale}.reset_confirmation_title`)}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {t(`${signup_locale}.reset_confirmation_body`)}
                    </Typography>
                }
                open={onViewResetForm} 
                onClose={handleCloseClearForm}
                onSuccess={handleOnClear}
            />
        </>
    );
}

export default HoroscopeGrid;

HoroscopeGrid.propTypes = {
    horoscopeInfo: PropTypes.array,
    view: PropTypes.bool,
    width: PropTypes.string,
    setHoroscopeList: PropTypes.func,
    clearHoroscopeList: PropTypes.func,
    title: PropTypes.string
}