import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import PropTypes from 'prop-types';

import { useCallback, useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import { 
    Grid,
    Typography,
    Box,
    styled,
    Stack,
    ToggleButtonGroup,
    ToggleButton,
    IconButton,
    Tooltip
} from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { 
    TextFieldComponent, 
    SelectionComponent, 
    ButtonComponent, 
    RadioButton,
    LoadingButtonComponent,
    CurrencyComponent
} from "../../../components/form";

import BannerLayout from "../banner-layout";
import { useTranslation } from "react-i18next";

// import { countries } from "../../../assets/data/countries";

import DatePickerComponent from "../../../components/form/date-picker-component";
import TimePickerComponent from "../../../components/form/time-picker-component";

import MdiShow from "../../../assets/icons/mdi-show";
import MdiHide from '../../../assets/icons/mdi-hide';
import ArrowLeft from '../../../assets/icons/arrow-left'

import HoroscopeGrid from '../../../components/custom-horoscope/horoscope-grid';
import FileUpload from '../../../components/form/file-upload';
import FormProviderComponent from '../../../components/form/form-provider-component';

import {
    genderList,
    starList,
    raasiList,
    educationLevelList,
    physicalStatusList,
    bodyTypeList,
    decisionList
} from '../../../assets/data/profile';
import { useGetSpecificMasterDataQuery, useGetSubCasteWithCasteIDMutation } from '../../../services/master-data-api';
import { useRegisterMutation } from '../../../services/auth-api';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';

import { decrypt } from "../../../contexts/utils";
import { validateEmail } from '../../../utils/custom-functions';
import { useAnalytics } from '../../../contexts/analytics/analytics-provider';
import InfoIcon from '../../../assets/icons/info-icon';
import { useCountries } from 'use-react-countries';
import { useNavigate } from 'react-router-dom';

const SignupForm = ({signupSuccessCallback, setEmail, setPassword}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const sign_up_locale = "authentication.sign_up";
    const validation_locale = "validation";
    const profile_locale = "profile.horoscope";

    const { countries } = useCountries();

    const [step, setStep] = useState(1);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [selectedImage, setSelectedImage] = useState([]);

    const [profileCreatedBy, setProfileCreatedBy] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');

    const [hasDosham, setHasDosham] = useState('YES');

    const [horoscopeDetails, setHoroscopeDetails] = useState([]);
    const [nawamsamDetails, setNawamsamDetails] = useState([]);

    const [horoscopeError, setHoroscopeError] = useState('');
    const [nawamsamError, setNawamsamError] = useState('');

    const [horoscopeType, setHoroscopeType] = useState('kattam');

    const {logCustomEvent, logCustomEventWithParams} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);
    const logAnalyticsWithParam = useCallback((event, param) => {
        logCustomEventWithParams(event, param);
    }, []);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        const encryptedData = localStorage.getItem('creator');
       
        if(encryptedData){
            const decryptedData = decrypt(encryptedData);

            const creatorObj = JSON.parse(decryptedData);

            console.log(creatorObj);
    
            setProfileCreatedBy(creatorObj.created_by);
            setMobileNumber(creatorObj.mobile);
    
            console.log("Profile created by " + creatorObj.created_by);
            console.log("Mobile number " + creatorObj.mobile);
        }
        else{
            console.log(">>>>>>>>>> Need to go home");
            navigate('/');
        }
    }, []);

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [religionList, setReligionList] = useState([]);
    const {isLoading: isLoadingReligion, isSuccess: isSuccessReligion, data: dataReligion, isError: isErrorReligion, error: errorReligion} = useGetSpecificMasterDataQuery('religion');
    useEffect(() => {
        if(isSuccessReligion && dataReligion){
            console.log("Religion master data...");
            console.log(dataReligion);

            setReligionList(getListData(dataReligion.data));
        }
        else if(isErrorReligion && errorReligion){
            console.log("Religion master data error...");
            console.log(errorReligion);

            setReligionList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessReligion, dataReligion, isErrorReligion, errorReligion]);

    const [castList, setCastList] = useState([]);
    const {isLoading: isLoadingCaste, isSuccess: isSuccessCaste, data: dataCaste, isError: isErrorCaste, error: errorCaste} = useGetSpecificMasterDataQuery('caste');
    useEffect(() => {
        if(isSuccessCaste && dataCaste){
            console.log("Caste master data...");
            console.log(dataCaste);

            setCastList(getListData(dataCaste.data));
        }
        else if(isErrorCaste && errorCaste){
            console.log("Caste master data error...");
            console.log(errorCaste);

            setCastList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessCaste, dataCaste, isErrorCaste, errorCaste]);

    const [subCastList, setSubCastList] = useState([]);
    const [getSubCasteList, {isLoading: isLoadingSubCasteList, isSuccess: isSuccessSubCasteList, data: dataSubCasteList, isError: isErrorSubCasteList, error: errorSubCasteList}] = useGetSubCasteWithCasteIDMutation();
    useEffect(() => {
        if(isSuccessSubCasteList && dataSubCasteList){
            console.log("Sub caste master data...");
            console.log(dataSubCasteList);

            setSubCastList(getListData(dataSubCasteList.data));

            if(dataSubCasteList.data.length === 0){
                setShowSnackBar({
                    state: true,
                    message: t(`${sign_up_locale}.step1.form.no_sub_caste_available`),
                    color: "blue"
                });
            }
        }
        else if(isErrorSubCasteList && errorSubCasteList){
            console.log("Sub caste master data error...");
            console.log(errorSubCasteList);

            setSubCastList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessSubCasteList, dataSubCasteList, isErrorSubCasteList, errorSubCasteList]);

    const [motherTongueList, setMotherTongueList] = useState([]);
    const {isLoading: isLoadingMotherTongue, isSuccess: isSuccessMotherTongue, data: dataMotherTongue, isError: isErrorMotherTongue, error: errorMotherTongue} = useGetSpecificMasterDataQuery('mother-tongue');
    useEffect(() => {
        if(isSuccessMotherTongue && dataMotherTongue){
            console.log("Mother tongue master data...");
            console.log(dataMotherTongue);

            setMotherTongueList(getListData(dataMotherTongue.data));
        }
        else if(isErrorMotherTongue && errorMotherTongue){
            console.log("Mother tongue master data error...");
            console.log(errorMotherTongue);

            setMotherTongueList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessMotherTongue, dataMotherTongue, isErrorMotherTongue, errorMotherTongue]);

    const [doshamList, setDoshamList] = useState([]);
    const {isLoading: isLoadingDosham, isSuccess: isSuccessDosham, data: dataDosham, isError: isErrorDosham, error: errorDosham} = useGetSpecificMasterDataQuery('dosha');
    useEffect(() => {
        if(isSuccessDosham && dataDosham){
            console.log("Dosham master data...");
            console.log(dataDosham);

            setDoshamList(getListData(dataDosham.data));
        }
        else if(isErrorDosham && errorDosham){
            console.log("Dosham master data error...");
            console.log(errorDosham);

            setDoshamList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessDosham, dataDosham, isErrorDosham, errorDosham]);

    const [maritalList, setMaritalList] = useState([]);
    const {isLoading: isLoadingMaritalStatus, isSuccess: isSuccessMaritalStatus, data: dataMaritalStatus, isError: isErrorMaritalStatus, error: errorMaritalStatus} = useGetSpecificMasterDataQuery('marital-status');
    useEffect(() => {
        if(isSuccessMaritalStatus && dataMaritalStatus){
            console.log("Marital status master data...");
            console.log(dataMaritalStatus);

            setMaritalList(getListData(dataMaritalStatus.data));
        }
        else if(isErrorMaritalStatus && errorMaritalStatus){
            console.log("Marital status master data error...");
            console.log(errorMaritalStatus);

            setMaritalList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessMaritalStatus, dataMaritalStatus, isErrorMaritalStatus, errorMaritalStatus]);

    const [employmentTypeList, setEmploymentTypeList] = useState([]);
    const {isLoading: isLoadingEmploymentType, isSuccess: isSuccessEmploymentType, data: dataEmploymentType, isError: isErrorEmploymentType, error: errorEmploymentType} = useGetSpecificMasterDataQuery('employment-type');
    useEffect(() => {
        if(isSuccessEmploymentType && dataEmploymentType){
            console.log("Employment type master data...");
            console.log(dataEmploymentType);

            setEmploymentTypeList(getListData(dataEmploymentType.data));
        }
        else if(isErrorEmploymentType && errorEmploymentType){
            console.log("Employment type master data error...");
            console.log(errorEmploymentType);

            setEmploymentTypeList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessEmploymentType, dataEmploymentType, isErrorEmploymentType, errorEmploymentType]);

    const [residenceTypeList, setResidenceTypeList] = useState([]);
    const {isLoading: isLoadingResidenceType, isSuccess: isSuccessResidenceType, data: dataResidenceType, isError: isErrorResidenceType, error: errorResidenceType} = useGetSpecificMasterDataQuery('residence-status');
    useEffect(() => {
        if(isSuccessResidenceType && dataResidenceType){
            console.log("Residence type master data...");
            console.log(dataResidenceType);

            setResidenceTypeList(getListData(dataResidenceType.data));
        }
        else if(isErrorResidenceType && errorResidenceType){
            console.log("Residence type master data error...");
            console.log(errorResidenceType);

            setResidenceTypeList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessResidenceType, dataResidenceType, isErrorResidenceType, errorResidenceType]);

    const getCompletedPercentage = useCallback(() => {
        const percentage = Math.round(step * 16.66);
        return percentage >= 99 ? 100 : percentage;
    }, [step]);

    const BorderLinearProgress = styled(LinearProgress)(({ }) => ({
        height: 5,
        borderRadius: 5,
        width: '100%',
        marginTop: 15,
        marginBottom: 35,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#FEE0D7',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
        },
    }));

    const switchPasswordVisibility = useCallback(() => {
        setShowPassword((status) => !status);
    }, []);

    const switchConfirmPasswordVisibility = useCallback(() => {
        setShowConfirmPassword((status) => !status);
    }, []);

    const isValidateHoroscope = useCallback(() => {
        console.log(horoscopeDetails);

        if(!isKattamSelected()){
            return true;
        }

        let planetsCount = 0;

        for(let i = 0; i < horoscopeDetails.length; i++){
            planetsCount += horoscopeDetails[i].planets.length;
        }

        return planetsCount === 9;
    }, [horoscopeDetails, horoscopeType]);

    const isValidateNawamsam = useCallback(() => {
        console.log(nawamsamDetails);

        if(isKattamSelected()){
            return true;
        }

        let planetsCount = 0;

        for(let i = 0; i < nawamsamDetails.length; i++){
            planetsCount += nawamsamDetails[i].planets.length;
        }

        return planetsCount === 9;
    }, [nawamsamDetails, horoscopeType]);

    const step1Schema = yup.object().shape({
        fname: yup.string().required(t(`${sign_up_locale}.errors.first_name_is_required`)).min(4, t(`${sign_up_locale}.errors.first_name_invalid`)),
        lname: yup.string().required(t(`${sign_up_locale}.errors.last_name_is_required`)).min(4, t(`${sign_up_locale}.errors.last_name_invalid`)),
        gender: yup.string().required(t(`${sign_up_locale}.errors.gender_is_required`)),
        dob: yup.string().required(t(`${sign_up_locale}.errors.dob_is_required`)),
        religion: yup.string().required(t(`${sign_up_locale}.errors.religion_is_required`)),
        caste: yup.string(),
        subCaste: yup.string(),
        rashi: yup.string(),
        star: yup.string(),
        dosham: yup.string().test('isValidDosham', t(`${sign_up_locale}.errors.dosham_is_required`), () => {
            const isValid = hasDosham === 'NO' || (hasDosham === 'YES' && step1Values.dosham !== '');
            return isValid;
        }),
        birthTime: yup.string().required(t(`${sign_up_locale}.errors.birth_time_is_required`)),
        birthPlace: yup.string().required(t(`${sign_up_locale}.errors.birth_place_is_required`)),
        birthCountry: yup.string().required(t(`${sign_up_locale}.errors.birth_country_is_required`)),
        motherTongue: yup.string().required(t(`${sign_up_locale}.errors.mother_tongue_is_required`)),
        email: yup.string().required(t(`${sign_up_locale}.errors.email_is_required`)).test('isValidEmail', t(`${sign_up_locale}.errors.invalid_email`), () => {
            return validateEmail(step1Values.email);
        }),
        password: yup.string().required(t(`${sign_up_locale}.errors.password_is_required`)).test('isValidPassword', t(`${sign_up_locale}.step1.form.password_strength`), () => {
            const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
            
            const isValid = passwordRegex.test(step1Values.password);
            return isValid;
        }),
        confirmPassword: yup.string().required(t(`${sign_up_locale}.errors.confirm_password_is_required`)).test('isValidConfirmPassword', t(`${sign_up_locale}.errors.passwords_are_mismatching`), () => {
            const isValid = step1Values.password === step1Values.confirmPassword;
            return isValid;
        })
    });

    const defaultStep1Values = {
        fname: '',
        lname: '',
        gender: '',
        dob: '',
        religion: '',
        caste: '',
        subCaste: '',
        rashi: '',
        star: '',
        dosham: '',
        birthTime: '',
        birthPlace: '',
        birthCountry: '',
        motherTongue: '',
        email: '',
        password: '',
        confirmPassword: ''
    };

    const step1Methods = useForm({
        resolver: yupResolver(step1Schema),
        defaultValues: defaultStep1Values
    });

    const {
        reset: resetStep1Form,
        handleSubmit: handleStep1Submit,
        formState: { isSubmitting: isSubmittingStep1 },
        watch: step1Watch,
        setValue: setStep1Values
    } = step1Methods;

    const step1Values = step1Watch();

    const step2Schema = yup.object().shape({
        height: yup.number().required(t(`${sign_up_locale}.errors.height_is_required`)).min(100).max(250).test('isValidHeight', t(`${sign_up_locale}.errors.invalid_height`), () => {
            const isValid = Number.isInteger(step2Values.height);
            return isValid;
        }),
        maritalStatus: yup.string().required(t(`${sign_up_locale}.errors.marital_status_is_required`)),
        physicalAppearance: yup.string().required(t(`${sign_up_locale}.errors.physical_status_required`)),
        bodyType: yup.string().notRequired()       
    });

    const defaultStep2Values = {
        height: 0,
        maritalStatus: '',
        physicalAppearance: '',
        bodyType: ''
    };

    const step2Methods = useForm({
        resolver: yupResolver(step2Schema),
        defaultValues: defaultStep2Values
    });

    const {
        reset: resetStep2Form,
        handleSubmit: handleStep2Submit,
        formState: { isSubmitting: isSubmittingStep2 },
        watch: step2Watch
    } = step2Methods;

    const step2Values = step2Watch();

    const step3Schema = yup.object().shape({
        horoscope: yup.array().test('isHoroscopeValid', t(`${sign_up_locale}.errors.invalid_horoscope`), () => {
            const status = isValidateHoroscope();

            if(!status){
                setHoroscopeError(t(`${sign_up_locale}.errors.invalid_horoscope`));
            }
            else{
                if(isKattamSelected()){
                    setHoroscopeType('nawamsam');
                }

                setHoroscopeError('');
            }

            return status;
        }),
        nawamsam: yup.array().test('isNawamsamValid', t(`${sign_up_locale}.errors.invalid_horoscope`), () => {
            const status = isValidateNawamsam();

            if(!status){
                setNawamsamError(t(`${sign_up_locale}.errors.invalid_horoscope`));
            }
            else{
                setNawamsamError('');
            }

            return status;
        })
    });

    const defaultStep3Values = {
        horoscope: [],
        nawamsam: []
    };

    const step3Methods = useForm({
        resolver: yupResolver(step3Schema),
        defaultValues: defaultStep3Values
    });

    const {
        reset: resetStep3Form,
        handleSubmit: handleStep3Submit,
        formState: { isSubmitting: isSubmittingStep3 },
        watch: step3Watch
    } = step3Methods;

    const step3Values = step3Watch();

    const step4Schema = yup.object().shape({
        education: yup.string(),
        educationLevel: yup.string().required(t(`${sign_up_locale}.errors.education_level_required`)),
        employmentType: yup.string().required(t(`${sign_up_locale}.errors.employment_type_is_required`)),
        currentOccupation: yup.string().required(t(`${sign_up_locale}.errors.occupation_is_required`)).test('isValidOccupation', t(`${sign_up_locale}.errors.invalid_occupation`), () => {
            const isValid = step4Values.currentOccupation.trim().length <= 30;
            return isValid;
        }),
        countryOfLiving: yup.string().required(t(`${sign_up_locale}.errors.country_of_living_is_required`)),
        annualIncome: yup.string().test('isValidIncome', t(`${sign_up_locale}.errors.income_is_invalid`), () => {
            const isValid = !isNaN(step4Values.annualIncome);
            return isValid;
        }),
        currencyType: yup.string(),
        city: yup.string().required(t(`${sign_up_locale}.errors.your_city_label_required`)),
        citizenship: yup.string().required(t(`${sign_up_locale}.errors.citizenship_is_required`)),
        residentialStatus: yup.string().required(t(`${sign_up_locale}.errors.residential_status_is_required`))
    });

    const defaultStep4Values = {
        education: '',
        educationLevel: '',
        employmentType: '',
        currentOccupation: '',
        countryOfLiving: '',
        annualIncome: '',
        city: '',
        citizenship: '',
        residentialStatus: '',
        currencyType: 'US Dollar'
    };

    const step4Methods = useForm({
        resolver: yupResolver(step4Schema),
        defaultValues: defaultStep4Values
    });

    const {
        reset: resetStep4Form,
        handleSubmit: handleStep4Submit,
        formState: { isSubmitting: isSubmittingStep4 },
        watch: step4Watch
    } = step4Methods;

    const step4Values = step4Watch();

    const step5Schema = yup.object().shape({
        aboutMe: yup.string().test('isValidAbout', t(`${sign_up_locale}.errors.invalid_about`), () => {
            const isValid = step5Values.aboutMe.length <= 250;
            return isValid;
        })
    });

    const defaultStep5Values = {
        aboutMe: ''
    };

    const step5Methods = useForm({
        resolver: yupResolver(step5Schema),
        defaultValues: defaultStep5Values
    });

    const {
        reset: resetStep5Form,
        handleSubmit: handleStep5Submit,
        formState: { isSubmitting: isSubmittingStep5 },
        watch: step5Watch
    } = step5Methods;

    const step5Values = step5Watch();

    const step6Schema = yup.object().shape({
        profile: yup.array().test('isValidProfile', t(`${sign_up_locale}.errors.invalid_profile_image`), () => {
            const isValid = selectedImage.length === 0 || (selectedImage.length >= 3 && selectedImage.length <= 10);
            return isValid;
        }),
    });

    const defaultStep6Values = {
        profile: []
    };

    const step6Methods = useForm({
        resolver: yupResolver(step6Schema),
        defaultValues: defaultStep6Values
    });

    const {
        reset: resetStep6Form,
        handleSubmit: handleStep6Submit,
        formState: { isSubmitting: isSubmittingStep6 },
        watch: step6Watch
    } = step6Methods;

    const step6Values = step6Watch();

    const [isImagesUnderUploadState, setIsImagesUnderUploadState] = useState(false);

    const convertDob = useCallback((dob) => {
        const inputDate = new Date(dob);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }, []);

    const convertBirthTime = useCallback((birthTime) => {
        let d = new Date(birthTime);
        let dateText = d.toTimeString();

        return dateText.split(' ')[0];
    }, []);

    const getProfileImageList = useCallback(() => {
        const array = [];

        for(let i = 0; i < selectedImage.length; i++){
            array.push(selectedImage[i].downloadURL);
        }

        return array;
    }, [selectedImage]);

    const getAge = useCallback(() => {
        const today = new Date();
        const birthDate = new Date(convertDob(step1Values.dob));
        const m = today.getMonth() - birthDate.getMonth();

        var age = today.getFullYear() - birthDate.getFullYear();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }, [step1Values]);

    const getAboutMeDetails = useCallback(() => {
        if(step5Values.aboutMe.trim() !== ""){
            return step5Values.aboutMe.trim();
        }
        else{
            const country = step4Values.countryOfLiving.charAt(0).toUpperCase() + step4Values.countryOfLiving.slice(1).toLowerCase();
            const about = "I'm a " + getAge() + "-year-old " + step4Values.currentOccupation + " from " + country + ". I'm seeking a partner who shares my values and is ready to build a strong and loving life. Together, we can create a harmonious and fulfilling relationship.";
            return about;
        }
    }, [step4Values, step5Values]);

    const [
        registerUser,
        {
            isLoading: isLoadingRegister,
            isSuccess: isSuccessRegister,
            data: dataRegister,
            isError: isErrorRegister,
            error: errorRegister
        }
    ] = useRegisterMutation();

    useEffect(() => {
        if(isSuccessRegister){
            setShowSnackBar({
                state: true,
                message: t(`${sign_up_locale}.registration_success`),
                color: "green"
            });

            localStorage.removeItem('creator');

            logAnalytics('NEW_USER_REGISTERED');

            signupSuccessCallback(true);
        }
        else if(isErrorRegister && errorRegister){
            console.log(errorRegister);

            logAnalyticsWithParam('NEW_USER_REGISTRATION_FAILED', {message: errorRegister.data.error.message});

            setShowSnackBar({
                state: true,
                message: `${errorRegister.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessRegister, isErrorRegister, errorRegister, signupSuccessCallback]);

    const register = useCallback(async () => {
        const obj = {
            "profileCreatedBy": !profileCreatedBy ? null : profileCreatedBy,
            "firstName": step1Values.fname,
            "lastName": step1Values.lname,
            "email": step1Values.email,
            "religion": step1Values.religion,
            "motherTongue": step1Values.motherTongue,
            "dateOfBirth": convertDob(step1Values.dob),
            "gender": !step1Values.gender ? null : step1Values.gender,
            "maritalStatus": step2Values.maritalStatus,
            "height": step2Values.height,
            "occupation": step4Values.currentOccupation,
            "employmentType": step4Values.employmentType,
            "timeOfBirth": convertBirthTime(step1Values.birthTime),
            "password": step1Values.password,
            "mobileNumber": mobileNumber,
            "countryOfLiving": step4Values.countryOfLiving,
            "citizenship": step4Values.citizenship,
            "residenceStatus": step4Values.residentialStatus,
            "hometown": step4Values.city,
            "caste": step1Values.caste,
            "subCaste": step1Values.subCaste,
            "rashi": !step1Values.rashi ? null : step1Values.rashi,
            "star": step1Values.star,
            "dosham": hasDosham === 'YES' ? step1Values.dosham : 'No',
            "physicalStatus": !step2Values.physicalAppearance ? null : step2Values.physicalAppearance,
            "bodyType": !step2Values.bodyType ? null : step2Values.bodyType,
            "education": step4Values.education,
            "educationLevel": !step4Values.educationLevel ? null : step4Values.educationLevel,
            "currency": step4Values.currencyType,
            "annualIncome": step4Values.annualIncome,
            "horoscopes": horoscopeDetails,
            "nawamsam": nawamsamDetails,
            "birthCity": step1Values.birthPlace,
            "birthCountry": step1Values.birthCountry,
            "bio": getAboutMeDetails(),
            "profileImages": getProfileImageList()
        }

        console.log(obj);

        setEmail(step1Values.email);
        setPassword(step1Values.password);

        await registerUser(obj);
    }, [profileCreatedBy, step1Values, step2Values, horoscopeDetails, nawamsamDetails, step4Values, step6Values, mobileNumber, setEmail, setPassword, hasDosham]);

    const getCastIDFromName = useCallback((name) => {
        let id = "";

        for(let i = 0; i < castList.length; i++){
            if(castList[i].value === name){
                id = castList[i].id;
                break;
            }
        }

        return id;
    }, [castList]);

    const getCountryCodeList = () => {
        const list = [];

        // console.log(countries);

        countries.map(({emoji, name}, index) => {
            list.push({id: index, label: `${name} ${emoji}`, value: name});
        });

        list.sort((a, b) => {
            // Compare labels using localeCompare for case-insensitive and language-sensitive sorting
            return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
        });

        return list;
    }

    useEffect(() => {
        if(step1Values.caste){
            setStep1Values("subCaste", '');
            getSubCasteList({id: getCastIDFromName(step1Values.caste)})
        }
    }, [step1Values.caste]);

    const onStep1Submit = handleStep1Submit((data) => {
        console.log(data);

        try{
            setStep(2);
        }
        catch(error){
            console.log(error);
        }
    });

    const onStep2Submit = handleStep2Submit((data) => {
        console.log(data);

        try{
            setHoroscopeError('');
            setNawamsamError('');

            setHoroscopeType('kattam');
            
            setStep(3);
        }
        catch(error){
            console.log(error);
        }
    });

    const onStep3Submit = handleStep3Submit((data) => {
        try{
            if(isKattamSelected()){
                setHoroscopeType('nawamsam');
            }
            else{
                setStep(4);
            }
        }
        catch(error){
            console.log(error);
        }
    });

    const onStep4Submit = handleStep4Submit(async(data) => {
        console.log(data);

        try{
            setStep(5);
        }
        catch(error){
            console.log(error);
        }
    });

    const onStep5Submit = handleStep5Submit(async(data) => {
        console.log(data);

        try{
            setStep(6);
        }
        catch(error){
            console.log(error);
        }
    });

    const onStep6Submit = handleStep6Submit(async(data) => {
        if(isImagesUnderUploadState) {
            setShowSnackBar({
                state: true,
                message: t(`${validation_locale}.wait_until_upload`),
                color: "red"
            });

            return;
        }

        console.log(selectedImage);

        try{
            register();
        }
        catch(error){
            console.log(error);
        }
    });

    const getStep1Form = () => {
        return (
            <FormProviderComponent methods={step1Methods} onSubmit={onStep1Submit}>
                <Grid
                    xs={12}
                    container
                    direction='row'
                    justifyContent='space-between'
                >
                    <Grid xs={12} md={6} sx={{pr: {xs: 0, md: 2}}}>
                        <TextFieldComponent 
                            name='fname'
                            label={t(`${sign_up_locale}.step${step}.form.first_name_label`)}
                            required
                        />
                    </Grid>

                    <Grid xs={12} md={6}>
                        <TextFieldComponent 
                            name='lname'
                            label={t(`${sign_up_locale}.step${step}.form.last_name_label`)}
                            required
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Stack>
                            <RadioButton 
                                label={t(`${sign_up_locale}.step${step}.form.gender_label`)}
                                name='gender'
                                list={genderList}
                                sx={{my: 1}}
                                selectedValue={step1Values.gender}
                                required
                            />

                            <DatePickerComponent 
                                label={t(`${sign_up_locale}.step${step}.form.dob_label`)}
                                name="dob"
                                defaultValue={step1Values.dob}
                                required
                                minDate={new Date().setFullYear(new Date().getFullYear() - 75)}
                                maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                            />

                            <SelectionComponent 
                                name='religion'
                                label={t(`${sign_up_locale}.step${step}.form.religion_label`)}
                                list={religionList}
                                required
                            />

                            <SelectionComponent 
                                name='caste'
                                label={t(`${sign_up_locale}.step${step}.form.caste_label`)}
                                list={castList}
                            />

                            <SelectionComponent 
                                name='subCaste'
                                label={t(`${sign_up_locale}.step${step}.form.sub_caste_label`)}
                                list={subCastList}
                                disabled={!step1Values.caste || subCastList.length === 0}
                            />

                            <SelectionComponent 
                                name='rashi'
                                label={t(`${sign_up_locale}.step${step}.form.rashi_label`)}
                                list={raasiList}
                            />

                            <SelectionComponent 
                                name='star'
                                label={t(`${sign_up_locale}.step${step}.form.star_label`)}
                                list={starList}
                            />

                            {/* <RadioButton 
                                label={t(`${sign_up_locale}.step${step}.form.dosham_selection`)}
                                name='doshamSelection'
                                list={decisionList}
                                sx={{my: 1}}
                                selectedValue={hasDosham}
                                switchCallback={(value) => {
                                    setHasDosham(value);
                                }}
                                required
                            /> */}

                            {
                                hasDosham === 'YES'
                                ?
                                <SelectionComponent 
                                    name='dosham'
                                    label={t(`${sign_up_locale}.step${step}.form.dosham_label`)}
                                    list={doshamList}
                                    required
                                />
                                :
                                <></>
                            }
                            
                            <TimePickerComponent 
                                label={t(`${sign_up_locale}.step${step}.form.birth_time_label`)}
                                name="birthTime"
                                defaultValue={step1Values.birthTime}
                                required
                            />

                            <TextFieldComponent 
                                name='birthPlace'
                                label={t(`${sign_up_locale}.step${step}.form.your_birth_place_label`)}
                                required
                            />

                            <SelectionComponent 
                                name='birthCountry'
                                label={t(`${sign_up_locale}.step${step}.form.birth_country_label`)}
                                list={getCountryCodeList()}
                                required
                            />

                            <SelectionComponent 
                                name='motherTongue'
                                label={t(`${sign_up_locale}.step${step}.form.mother_tongue_label`)}
                                list={motherTongueList}
                                required
                            />

                            <TextFieldComponent 
                                name='email'
                                label={t(`${sign_up_locale}.step${step}.form.email_label`)}
                                required
                            />

                            <Grid

                                direction='row'
                                alignItems='center'
                                xs={12}
                                container
                            >
                                <Grid xs>
                                    <TextFieldComponent 
                                        name='password'
                                        label={t(`${sign_up_locale}.step${step}.form.password_label`)}
                                        type={showPassword ? 'text' : 'password'}
                                        required
                                        endIcon={
                                            showPassword 
                                            ?
                                            <MdiHide style={{width: '20px', color: '#637381'}}/> 
                                            : 
                                            <MdiShow style={{width: '20px', color: '#637381'}}/>
                                        }
                                        endIconCallback={switchPasswordVisibility}
                                        
                                    />
                                </Grid>
                                
                                <Grid xs='auto' ml={1}>
                                    <Tooltip title={t(`${sign_up_locale}.step${step}.form.password_strength`)}>
                                        <IconButton>
                                            <InfoIcon style={{color: 'gray'}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <TextFieldComponent 
                                name='confirmPassword'
                                label={t(`${sign_up_locale}.step${step}.form.confirm_password_label`)}
                                type={showConfirmPassword ? 'text' : 'password'}
                                required
                                endIcon={
                                    showConfirmPassword 
                                    ? 
                                    <MdiHide style={{width: '20px', color: '#637381'}}/> 
                                    : 
                                    <MdiShow style={{width: '20px', color: '#637381'}}/>
                                }
                                endIconCallback={switchConfirmPasswordVisibility}
                            />

                            <Stack alignItems='end'>
                                <ButtonComponent 
                                    text={t(`${sign_up_locale}.action`)}
                                    type="submit"
                                    sx={{
                                        width: 120,
                                        mt: 1,
                                        backgroundColor: 'black'
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </FormProviderComponent>
        )
    }

    const getStep2Form = () => {
        return <FormProviderComponent methods={step2Methods} onSubmit={onStep2Submit}>
            <Grid
                xs={12}
                container
                direction='row'
                justifyContent='space-between'
            >
                <Grid xs={12}>
                    <Stack>
                        <TextFieldComponent 
                            name='height'
                            label={t(`${sign_up_locale}.step${step}.form.height_label`)}
                            required
                            startUnit={t(`${sign_up_locale}.step${step}.form.height_unit`)}
                            type='number'
                        />

                        <SelectionComponent 
                            name='maritalStatus'
                            label={t(`${sign_up_locale}.step${step}.form.marital_status_label`)}
                            list={maritalList}
                            required
                        />

                        <RadioButton 
                            label={t(`${sign_up_locale}.step${step}.form.physical_appearance_label`)}
                            name='physicalAppearance'
                            list={physicalStatusList}
                            selectedValue={step2Values.physicalAppearance}
                            sx={{my: 1}}
                            required
                        />

                        <RadioButton 
                            label={t(`${sign_up_locale}.step${step}.form.body_type_label`)}
                            name='bodyType'
                            list={bodyTypeList}
                            selectedValue={step2Values.bodyType}
                            sx={{my: 1}}
                        />

                        <Stack alignItems='end'>
                            <ButtonComponent 
                                text={t(`${sign_up_locale}.action`)}
                                type="submit"
                                sx={{
                                    width: 120,
                                    mt: 1,
                                    backgroundColor: 'black'
                                }}
                            />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </FormProviderComponent>
    }

    const getStep3Form = () => {
        return <>
            <Grid
                xs={12}
                container
                direction='column'
                justifyContent='space-between'
            >
                <ToggleButtonGroup
                    color="primary"
                    value={horoscopeType}
                    exclusive
                    onChange={handleChangeHoroscope}
                    aria-label="Platform"
                >
                    <ToggleButton value="kattam">{t(`${profile_locale}.kattam`)}</ToggleButton>
                    <ToggleButton value="nawamsam">{t(`${profile_locale}.nawamsam`)}</ToggleButton>
                </ToggleButtonGroup>

                {
                    isKattamSelected()
                    ?
                    <>
                        <HoroscopeGrid 
                            setHoroscopeList={setHoroscopeList} 
                            clearHoroscopeList={clearHoroscopeList}
                            horoscopeInfo={horoscopeDetails}
                            title={t(`${sign_up_locale}.step3.form.horoscope_title`)}
                        />

                        <p style={{paddingLeft: '5px', color: 'red'}}>{horoscopeError}</p>
                    </>
                    :
                    <>
                        <div></div>

                        <HoroscopeGrid 
                            setHoroscopeList={setNawamsamList} 
                            clearHoroscopeList={clearNawamsamList}
                            horoscopeInfo={nawamsamDetails}
                            title={t(`${sign_up_locale}.step3.form.nawamsam_title`)}
                        />

                        <p style={{paddingLeft: '5px', color: 'red'}}>{nawamsamError}</p>
                    </>
                }
               
                <Grid 
                    xs={12} 
                    direction='row'
                    display='flex'
                    justifyContent='end'
                    alignItems='center'
                >
                    <Stack alignItems='end'>
                        <ButtonComponent 
                            text={t(`${sign_up_locale}.skip_action`)}
                            onClick={() => {
                                if(isKattamSelected()){
                                    clearHoroscopeList();
                                    setHoroscopeType('nawamsam');
                                }
                                else{
                                    clearNawamsamList();
                                    setStep(4);
                                }
                            }}
                            outlined={true}
                            color="secondary"
                            sx={{
                                width: 120,
                                mt: 1,
                                mr: 2
                            }}
                        />
                    </Stack>
                    
                    <Stack alignItems='end'>
                        <ButtonComponent 
                            text={t(`${sign_up_locale}.action`)}
                            onClick={onStep3Submit}
                            sx={{
                                width: 120,
                                mt: 1,
                                backgroundColor: 'black'
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </>
    }

    const getStep4Form = () => {
        return <FormProviderComponent methods={step4Methods} onSubmit={onStep4Submit}>
            <Grid
                xs={12}
                container
                direction='row'
                justifyContent='space-between'
            >
                <Grid xs={12} md={6} sx={{pr: {xs: 0, md: 2}}}>
                    <SelectionComponent 
                        name='educationLevel'
                        label={t(`${sign_up_locale}.step${step}.form.education_level_label`)}
                        list={educationLevelList}
                        required
                    />
                </Grid>

                <Grid xs={12} md={6}>
                    <TextFieldComponent 
                        name='education'
                        label={t(`${sign_up_locale}.step${step}.form.education_label`)}
                    />
                </Grid>

                <Grid xs={12} md={6} sx={{pr: {xs: 0, md: 2}}}>
                    <SelectionComponent 
                        name='employmentType'
                        label={t(`${sign_up_locale}.step${step}.form.employment_type_label`)}
                        list={employmentTypeList}
                        required
                    />
                </Grid>

                <Grid xs={12} md={6}>
                    <TextFieldComponent 
                        name='currentOccupation'
                        label={t(`${sign_up_locale}.step${step}.form.your_current_occupation_label`)}
                        required
                    />
                </Grid>

                <Grid xs={12} md={6} sx={{pr: {xs: 0, md: 2}}}>
                    <SelectionComponent 
                        name='countryOfLiving'
                        label={t(`${sign_up_locale}.step${step}.form.your_country_of_living_label`)}
                        list={getCountryCodeList()}
                        required
                    />
                </Grid>

                <Grid xs={12} md={6} sx={{pr: {xs: 0, md: 2}}}>
                    <SelectionComponent 
                        name='residentialStatus'
                        label={t(`${sign_up_locale}.step${step}.form.residential_status_label`)}
                        list={residenceTypeList}
                        required
                    />
                </Grid>

                <Grid xs={12} md={6} sx={{pr: {xs: 0, md: 2}}}>
                    <TextFieldComponent 
                        name='city'
                        label={t(`${sign_up_locale}.step${step}.form.your_city_label`)}
                        required
                    />
                </Grid>

                <Grid xs={12} md={6}>
                    <SelectionComponent 
                        name='citizenship'
                        label={t(`${sign_up_locale}.step${step}.form.your_citizenship_label`)}
                        list={getCountryCodeList()}
                        required
                    />
                </Grid>

                <Grid xs={12} container direction='row' alignItems='center'>
                    <CurrencyComponent 
                        label={t(`${sign_up_locale}.step${step}.form.your_annual_income_label`)}
                        name='annualIncome'
                        currency_name='currencyType'
                        currency_label=''
                    />
                </Grid>

                <Grid xs={12}>
                    <Stack>
                        <Stack alignItems='end'>
                            <ButtonComponent 
                                text={t(`${sign_up_locale}.action`)}
                                type="submit"
                                sx={{
                                    width: 120,
                                    mt: 1,
                                    backgroundColor: 'black'
                                }}
                            />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </FormProviderComponent>
    }

    const getStep5Form = () => {
        return <FormProviderComponent methods={step5Methods} onSubmit={onStep5Submit}>
            <Grid
                xs={12}
                container
                direction='row'
                justifyContent='space-between'
            >
                <Grid xs={12}>
                    <TextFieldComponent 
                        name='aboutMe'
                        label={t(`${sign_up_locale}.step${step}.form.about_you_label`)}
                        multiline
                        rows={4}
                        helper={t(`${sign_up_locale}.step${step}.form.about_you_label_instruction`)}
                    />
                </Grid>

                <Grid xs={12}>
                    <Stack alignItems='end'>
                        <ButtonComponent 
                            text={t(`${sign_up_locale}.action`)}
                            type="submit"
                            sx={{
                                width: 120,
                                mt: 1,
                                backgroundColor: 'black'
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </FormProviderComponent>
    }

    const getStep6Form = () => {
        return <FormProviderComponent methods={step6Methods} onSubmit={onStep6Submit}>
            <Grid
                xs={12}
                container
                direction='row'
                justifyContent='space-between'
            >
                <Grid
                    xs={12}
                    container
                    direction='row'
                    justifyContent='space-between'
                >
                    <Grid xs={12}>
                        <FileUpload 
                            name="profile"
                            label={t(`${sign_up_locale}.step6.form.image_upload_label`)}
                            min={3}
                            max={10}
                            imageList={selectedImage}
                            fileExtensionList={['png', 'jpeg', 'jpg']}
                            setImageList={setSelectedImage}
                            uploadType='PROFILE_IMG'
                            setIsImagesUnderUploadState={setIsImagesUnderUploadState}
                        />
                    </Grid>

                    <Grid 
                        xs={12} 
                        direction='row'
                        display='flex'
                        justifyContent='end'
                        alignItems='center'
                    >
                        <Stack alignItems='end'>
                            <LoadingButtonComponent 
                                text={t(`${sign_up_locale}.skip_action`)}
                                type="submit"
                                outlined={true}
                                color="secondary"
                                inLoading={isLoadingRegister}
                                sx={{
                                    width: 120,
                                    mt: 1,
                                    mr: 2
                                }}
                            />
                        </Stack>

                        <Stack alignItems='end'>
                            <LoadingButtonComponent 
                                text={t(`${sign_up_locale}.action`)}
                                type="submit"
                                inLoading={isLoadingRegister}
                                disabled={isImagesUnderUploadState}
                                sx={{
                                    width: 120,
                                    mt: 1,
                                    backgroundColor: 'black'
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </FormProviderComponent>
    }

    const getForm = () => {
        switch(step){
            case 1:
                return getStep1Form();

            case 2:
                return getStep2Form();

            case 3:
                return getStep3Form();

            case 4:
                return getStep4Form();

            case 5:
                return getStep5Form();

            case 6:
                return getStep6Form();

            default:
                return <></>
        }
    }

    const getFormBackgroundIcon = useCallback(() => {
        switch(step){
            case 1:
                return '/assets/icons/authentication-4.png';

            case 2:
                return '/assets/icons/authentication-8.png';

            case 3:
                return '';

            case 4:
                return '/assets/icons/authentication-8.png';

            case 5:
                return '/assets/icons/authentication-6.png';

            case 6:
                return '/assets/icons/authentication-9.png';

            default:
                return ''
        }
    }, [step]);

    const setHoroscopeList = useCallback((lagnaID, array) => {
        const horoscopeArray = [];

        for(let i = 0; i < array.length; i++){
            const planets = [];

            for(let j = 0; j < array[i].length; j++){
                planets.push(array[i][j].value);
            }

            let id = i >= lagnaID ? i - lagnaID + 1 : 12 - lagnaID + 1 + i;

            horoscopeArray.push({id: id, planets: planets});
        }

        console.log(horoscopeArray);

        setHoroscopeDetails(horoscopeArray);
    }, []);

    const setNawamsamList = useCallback((lagnaID, array) => {
        const nawamsamArray = [];

        for(let i = 0; i < array.length; i++){
            const planets = [];

            for(let j = 0; j < array[i].length; j++){
                planets.push(array[i][j].value);
            }

            let id = i >= lagnaID ? i - lagnaID + 1 : 12 - lagnaID + 1 + i;

            nawamsamArray.push({id: id, planets: planets});
        }

        console.log(nawamsamArray);

        setNawamsamDetails(nawamsamArray);
    }, []);

    const clearHoroscopeList = useCallback(() => {
        console.log("Horoscope cleared...");

        setHoroscopeError('');
        setHoroscopeDetails([]);
    }, []);

    const clearNawamsamList = useCallback(() => {
        console.log("Nawamsam cleared...");

        setNawamsamError('');
        setNawamsamDetails([]);
    }, []);

    const handleChangeHoroscope = useCallback((event, newAlignment) => {
        setHoroscopeError('');
        setNawamsamError('');

        setHoroscopeType(newAlignment);
    }, []);

    const isKattamSelected = useCallback(() => {
        return horoscopeType === 'kattam';
    }, [horoscopeType]);

    return(
        <>
            <BannerLayout 
                title={t(`${sign_up_locale}.step${step}.heading`)}
                caption={t(`${sign_up_locale}.step${step}.caption`)}
                step={`${t(`${sign_up_locale}.step_keyword`)} ${step}`}
                verticalImg={`/assets/images/signup/step${step}-portrait.png`}
                landscapeImg={`/assets/images/signup/step${step}-landscape.png`}
                children={
                    <Grid
                        xs
                        container
                        display='flex'
                        direction='column'
                        alignItems='start'
                        justifyContent='start'
                        sx={{
                            px: {xs: 4, md: 10}
                        }}
                    >
                        {
                            step === 1
                            ?
                            <></>
                            :
                            <ButtonComponent 
                                text={t(`${sign_up_locale}.back`)}
                                onClick={() => {
                                    setHoroscopeError('');

                                    setStep((value) => value - 1);
                                }}
                                width={124}
                                startIcon={<ArrowLeft style={{width: '22px', color: theme.palette.primary.main}}/>}
                                variant='text'
                                sx={{
                                    color: theme.palette.primary.main,
                                    textTransform: 'capitalize',
                                    mb: 2,
                                    textSize: 16
                                }}
                            />
                        }

                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: 16
                            }}
                        >
                            {`${t(`${sign_up_locale}.progress1`)} ${getCompletedPercentage()}% ${t(`${sign_up_locale}.progress2`)}`}
                        </Typography>

                        <BorderLinearProgress variant="determinate" value={getCompletedPercentage()}/>

                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: 16,
                                color: '#6D6D6D',
                                py: 2
                            }}
                        >
                            {t(`${sign_up_locale}.step${step}.form.heading`)}
                        </Typography>

                        <Box
                            sx={{ 
                                backgroundImage: `url(${getFormBackgroundIcon()})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPositionX: 'right',
                                width: '100%'
                            }}
                        >
                            {getForm()}
                        </Box>
                    </Grid>
                }
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />          
        </>
    );
}

export default SignupForm;

SignupForm.propTypes = {
    signupSuccessCallback: PropTypes.func,
    setEmail: PropTypes.func,
    setPassword: PropTypes.func
}