import { 
    Grid,
    Box,
    Typography
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Footer from '../../footer/footer';
import { useCallback, useEffect, useState } from "react";
import { useCustomLoginMutation, useGetCustomEmailMutation } from "../../../services/auth-api";
import { LoadingButtonComponent } from "../../../components/form";
import { useAuth } from "../../../contexts/auth/auth-provider";
import { useAnalytics } from "../../../contexts/analytics/analytics-provider";

const SignupConfirmation = ({email, password}) => {
    const { t } = useTranslation();
    const congratulations_locale = "authentication.congratulation";

    const [submitLoading, setSubmitLoading] = useState(false);

    const [firebaseAccessToken, setFirebaseAccessToken] = useState(null);

    const navigate = useNavigate();

    const authState = useAuth();

    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const [
        getCustomEmail, 
        {
            isLoading: isGetCustomEmailLoading,
            isSuccess: isGetCustomEmailSuccess,
            isError: isGetCustomEmailError,
            data: dataGetCustomEmailToken,
            error: errorGetCustomEmailToken
        }
    ] = useGetCustomEmailMutation();

    //getCustomEmail success
    useEffect(() => {
        if (isGetCustomEmailSuccess && dataGetCustomEmailToken) {
            console.log(dataGetCustomEmailToken);
            firebaseLogin(dataGetCustomEmailToken.data);
        }
    }, [isGetCustomEmailSuccess, dataGetCustomEmailToken]);

    //getCustomEmail error
    useEffect(() => {
        if (isGetCustomEmailError && errorGetCustomEmailToken) {
            setSubmitLoading(false);
            console.log(errorGetCustomEmailToken);
    
            navigate('/login');
        }
    }, [isGetCustomEmailError, errorGetCustomEmailToken, navigate]);

    const firebaseLogin = useCallback(async (userName) => {
        try{
            console.log("Firebase login requested...");
            
            const accessToken = await authState.login?.(userName, password);
            console.log("Firebase accessToken...");
            console.log(accessToken);

            setFirebaseAccessToken(accessToken);
        }
        catch(error){
            setSubmitLoading(false);
            console.error(error);

            navigate('/login');
        }
    }, [authState, password, navigate]);

    //Call after temporarily stored firebase response
    useEffect(() => {
        if(firebaseAccessToken){
            callCustomLogin(firebaseAccessToken);
        }
    }, [firebaseAccessToken]);

    const [
        customLogin, 
        {
            isLoading: isCustomLoginLoading,
            isSuccess: isCustomLoginSuccess,
            isError: isCustomLoginError,
            data: dataCustomLogin,
            error: errorCustomLogin
        }
    ] = useCustomLoginMutation();

    const callCustomLogin = useCallback(async (idToken) => {
        await customLogin(idToken);
    }, []);

    //customLogin success
    useEffect(() => {
        if (isCustomLoginSuccess && dataCustomLogin) {
            console.log(dataCustomLogin);

            setSubmitLoading(false);

            authState.saveLoginResponse?.(dataCustomLogin.data);

            logAnalytics('SIGNIN');

            navigate('/feed');         
        }
    }, [authState, isCustomLoginSuccess, dataCustomLogin, navigate]);

    //customLogin error
    useEffect(() => {
        if (isCustomLoginError && errorCustomLogin) {
            setSubmitLoading(false);
            console.log(errorCustomLogin);

            navigate('/login');
        }
    }, [isCustomLoginError, errorCustomLogin, navigate]);

    const autoUserLogIn = useCallback(async () => {
        if(email && password){
            setSubmitLoading(true);

            await getCustomEmail(email);
        }
        else{
            navigate('/login');
        }
    }, [email, password]);

    return(
        <>
            <Grid
                xs
                container
                sx={{height: '100vh'}}
            >
                {/* Body */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{px: {xs: 5, md: 25, lg: 50}, textAlign: 'center'}}
                >

                    {/* Bird Illustration */}
                    <Box
                        component="img"
                        sx={{

                            content: `url('/assets/icons/authentication-4.png')`,
                            width: {xs: 225, md: 291, lg: 402},
                            position: 'absolute',
                            right: 50,
                            top: 50,
                            zIndex: -1,
                        }}
                        alt="icon"
                    />

                    {/* Heart Illustration */}
                    <Box
                        component="img"
                        sx={{

                            content: `url('/assets/icons/authentication-5.png')`,
                            width: {xs: 375, md: 536, lg: 691},
                            position: 'absolute',
                            zIndex: -1,
                        }}
                        alt="icon"
                    />

                    {/* Title */}
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: 30, lg: 48}}
                        letterSpacing={-2} 
                    >
                        {t(`${congratulations_locale}.heading`)}
                    </Typography>

                    <Typography
                        fontWeight={600}
                        fontSize={20}
                        sx={{pt: 3}}
                        color='#6D6D6D'
                    >
                        {t(`${congratulations_locale}.caption`)}
                    </Typography>

                    <LoadingButtonComponent 
                        text={t(`${congratulations_locale}.action`)}
                        onClick={autoUserLogIn}
                        inLoading={submitLoading}
                        sx={{
                            mt: {xs: 0, lg: 3},
                            position: {xs: 'absolute', md: 'relative'},
                            bottom: 0,
                            mb: {xs: 2, lg: 0},
                            width: {xs: '75%', lg: 175},
                        }}
                    />
                </Grid>
            </Grid>

            <Footer />
        </>
    );
}

export default SignupConfirmation;