import PropTypes from 'prop-types';

import { 
    Chip,
    Link,
    Stack, 
    Typography,
    useTheme
} from '@mui/material';

import ButtonComponent from '../../../components/form/button-component';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '../../../assets/icons/chevron-right-icon';

const ProfileFeedListViewHeader = ({title, results, caption, viewAllLink}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_feed_locale = "profile_feed";

    const navigate = useNavigate();

    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                py={3}
                mt={5}
            >
                <Stack
                    display='flex'
                    direction={{xs: 'column', md: 'row'}}
                    justifyContent={{xs: 'start', md: 'space-between'}}
                    sx={{width: '100%'}}
                >
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: {xs: 16, md: 16, lg: 24},
                                color: '#121212'
                            }}
                        >
                            {title}
                        </Typography>

                        {
                            results
                            ?
                            <Chip 
                                label={results}
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 12
                                }}
                            />
                            :
                            <></>
                        }
                    </Stack>

                    {
                        viewAllLink
                        ?
                        <Link 
                            onClick={() => navigate(viewAllLink)} 
                            underline='none' 
                            sx={{
                                cursor: 'pointer',
                                "&:hover": {
                                    transform: 'scale(1.03)'
                                }
                            }}
                        >
                            <Stack 
                                direction='row'
                                justifyContent='start'
                                alignItems='center'
                                sx={{
                                    py: {xs: 2, md: 0}
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: theme.palette.primary.main
                                    }}
                                >
                                    {t(`${profile_feed_locale}.view_all_results`)}
                                </Typography>

                                <ChevronRightIcon style={{width: '22px', color: theme.palette.primary.main}}/>
                            </Stack>
                        </Link>
                        :
                        <></>
                    }
                </Stack>

                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: 16,
                        color: '#6D6D6D'
                    }}
                >
                    {caption}
                </Typography>
            </Stack>
        </>
    );
}

export default ProfileFeedListViewHeader;

ProfileFeedListViewHeader.propType = {
    title: PropTypes.string, 
    results: PropTypes.number, 
    caption: PropTypes.string, 
    viewAllLink: PropTypes.string
}