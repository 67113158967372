import React, { useState, useContext, useEffect, useCallback } from "react";
import { AuthContext } from "./auth-context";
import { isValidToken, setSession, encrypt, decrypt, checkSessionValidity } from "../utils";
import { useNavigate } from "react-router-dom";

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
    signInWithEmailAndPassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
    signOut
} from 'firebase/auth';

const STORAGE_KEY = 'accessToken';
const STORAGE_USER_KEY = 'user';

export function AuthProvider({children}){
    const [initialized, setInitialized] = useState(false);
    const [userAuthenticationVerified, setUserAuthenticationVerified] = useState(-1);
    const [currentUser, setCurrentUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    const navigate = useNavigate();

    const firebaseApp = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID
    });

    const firebaseAuth = getAuth(firebaseApp);

    const logOut = () => {
        signOut(firebaseAuth);

        setCurrentUser(null);
        setAccessToken(null);
        setUserAuthenticationVerified(-1);

        setSession(null);
        
        // navigate("/");
    };

    const initialize = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem(STORAGE_KEY);
            const userCipherText = localStorage.getItem(STORAGE_USER_KEY);
        
            if (accessToken && isValidToken(accessToken) && userCipherText) {
                setSession(accessToken);
        
                const userData = decrypt(userCipherText);
                console.log(userData);

                setCurrentUser(JSON.parse(userData));
                setAccessToken("Bearer ".concat(accessToken));

                setInitialized(true);
            }
            
            setUserAuthenticationVerified(1);
        } catch (error) {
            console.error(error);
            logOut();
        }
    }, [logOut]);
    
    useEffect(() => {
        if(!initialized){
            initialize();
        }
    }, [initialized]);

    // LOGIN
    const login = useCallback(async (email, password) => {
        const firebaseResponse = await signInWithEmailAndPassword(firebaseAuth, email, password);
        console.log({ firebaseResponse });

        const accessToken = await firebaseAuth.currentUser?.getIdToken();
        
        return accessToken;
    }, []);

    const confirmPassword = useCallback(async (password) => {
        
        const credential = EmailAuthProvider.credential(
            firebaseAuth.currentUser.email,
            password
        );

        return await reauthenticateWithCredential(firebaseAuth.currentUser, credential);
    }, []);

    const getUserClaims = useCallback(async (callback) => {
        await firebaseAuth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
            console.log(">>>>>> User claims: ");
            console.log(idTokenResult.claims);
                
            callback(idTokenResult.claims);
        })
        .catch((error) => {
            console.log(error);
            callback(null);
        });
    }, []);

    // LOGIN RESPONSE
    const saveLoginResponse = useCallback((customResponse) => {
        console.log("Custom access token...");
        console.log(customResponse.accessToken);

        setSession(customResponse.accessToken);

        // accessToken: "eyJhbGciOiJIUzI1NiJ9.eyJhdXRob3JpdGllcyI6WyJVU0VSX0FOQUxZVElDU19SRUFEIiwiVVNFUl9FTkRVU0VSX0NSRUFURSIsIlVTRVJfRU5EVVNFUl9VUERBVEUiLCJVU0VSX0V…"
        // email: "prasanth15sp@gmail.com"
        // emailVerified: false
        // firstName: "Prasanth"
        // lastName: "Sivakumar"
        // mobileNumber: "+94761516649"
        // mobileVerified: true
        // nicVerified: false
        // profile: {dateOfBirth: "1996-03-15", gender: "MALE", height: 171, education: "Software engineering", occupation: "Software engineer", …}
        // uuid: "2xBidaZ8lpRtZAL8OJ7Mf2bU2O82"

        const user = {
            "email": customResponse.email,
            "emailVerified": customResponse.emailVerified,
            "firstName": customResponse.firstName,
            "lastName": customResponse.lastName,
            "nicVerified": customResponse.nicVerified,
            "uuid": customResponse.uuid,
            "profile": customResponse.profile?.profileImages?.length > 0 ? customResponse.profile.profileImages[0] : '',
            "gender": customResponse.profile?.gender,
            "memberID": customResponse.memberId,
            "mobileNumber": customResponse.mobileNumber
        }

        console.log("User...");
        console.log(user);

        const encryptedData = encrypt(JSON.stringify(user));
        localStorage.setItem(STORAGE_USER_KEY, encryptedData);

        setCurrentUser(user);
        setAccessToken("Bearer ".concat(customResponse.accessToken));

        setUserAuthenticationVerified(1);
    }, []);

    const value = {
        currentUser,
        accessToken,
        userAuthenticationVerified,
        firebaseApp,
        logOut,
        login,
        confirmPassword,
        saveLoginResponse,
        getUserClaims
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};