import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { useCallback, useEffect, useState } from "react";

import { 
    Stack,
    Typography,
    Box
} from "@mui/material";
import { useTranslation } from "react-i18next";

import CheckBoxComponent from "../../../components/form/check-box-component";
import TextFieldComponent from '../../../components/form/text-field-component';
import { ButtonComponent, LoadingButtonComponent, RadioButton } from "../../../components/form";
import { useNavigate } from "react-router-dom";

import MdiHide from "../../../assets/icons/mdi-hide";
import MdiShow from '../../../assets/icons/mdi-show';

import FormProviderComponent from '../../../components/form/form-provider-component';
import { useAuth } from '../../../contexts/auth/auth-provider';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useDeleteAccountMutation } from '../../../services/profile-api';
import { useAnalytics } from '../../../contexts/analytics/analytics-provider';
import DialogBox from '../../../components/Dialog/dialog-box';

const DeleteProfileBody = ({step, updateStep}) => {
    const { t } = useTranslation();
    const delete_profile_locale = "profile_settings.delete_profile";

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const schema = yup.object().shape({
        found: yup.boolean(),
        not_satisfied: yup.boolean(),
        privacy: yup.boolean(),
        too_many_message: yup.boolean(),
        prefer_other: yup.boolean(),
        other: yup.boolean(),
        otherReason: yup.string().test('isValidReason', t(`${delete_profile_locale}.errors.invalid_character_length`), () => {
            const isValid = value.otherReason.trim().length <= 100;
            return isValid;
        }),
        password: yup.string().test('isValidReason', t(`${delete_profile_locale}.errors.invalid_password`), () => {
            const isValid = step !== 2 || (step === 2 && value.password.trim().length > 0);
            return isValid;
        })
    });

    const defaultValues = {
        otherReason: '',
        found: false,
        not_satisfied: false,
        privacy: false,
        too_many_message: false,
        prefer_other: false,
        other: false,
        password: ''
    };

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch: watch,
        setValue: setValue,
        setError: setError
    } = methods;

    const value = watch();

    const [selectedFoundReason, setSelectedFoundReason] = useState("");

    const getFoundInRadioOptions = () => {
        return <RadioButton 
            label=""
            name='foundIn'
            list={[
                {name: 'eelam', label: t(`${delete_profile_locale}.step1.options.answer0`), value: 'Eelam Maangalyam'},
                {name: 'other', label: t(`${delete_profile_locale}.step1.options.answer1`), value: 'other matrimony sites'}
            ]}
            sx={{my: 1}}
            selectedValue={selectedFoundReason}
            disabledValueList={!value.found ? ['Eelam Maangalyam', 'other matrimony sites'] : ''}
            switchCallback={(value) => setSelectedFoundReason(value)}
        />
    };

    const questions = [
        {name: 'found', label: t(`${delete_profile_locale}.step1.options.answer7`), value: 'Found a match in ', child: getFoundInRadioOptions()},
        {name: 'not_satisfied', label: t(`${delete_profile_locale}.step1.options.answer2`), value: 'Not satisfied with the service'},
        {name: 'privacy', label: t(`${delete_profile_locale}.step1.options.answer3`), value: 'Privacy concerns'},
        {name: 'too_many_message', label: t(`${delete_profile_locale}.step1.options.answer4`), value: 'Too many unwanted messages'},
        {name: 'prefer_other', label: t(`${delete_profile_locale}.step1.options.answer5`), value: 'Prefer another matrimonial service'},
        {name: 'other', label: t(`${delete_profile_locale}.step1.options.answer6`), value: 'Other'}
    ];

    const auth = useAuth();

    const [recentCheckBoxError, setRecentCheckBoxError] = useState('');

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    const {logCustomEvent, logCustomEventWithParams} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const nextStep = useCallback(() => {
        if(step === 3){
            navigate('/');
        }
        else{
            updateStep();
        }
    }, [step]);

    const getSelectedCheckboxValues = useCallback(() => {
        const array = [];

        if(value.found){
            array.push(questions[0].value)
        }

        if(value.not_satisfied){
            array.push(questions[1].value);
        }

        if(value.privacy){
            array.push(questions[2].value);
        }

        if(value.too_many_message){
            array.push(questions[3].value);
        }

        if(value.prefer_other){
            array.push(questions[4].value);
        }

        if(value.other){
            array.push(questions[5].value);
        }

        return array;
    }, [value]);

    let body;

    if(step === 1){
        body = <Step1Body 
            questions={questions}
            checkboxLabel={t(`${delete_profile_locale}.step${step}.question`)}
            reasonLabel={t(`${delete_profile_locale}.step${step}.options.description`)}
            helper={t(`${delete_profile_locale}.step${step}.options.description_hint`)}
            onChange={setValue}
            error={recentCheckBoxError}
            selectedValue={getSelectedCheckboxValues()}
        />
    }
    else if(step === 2){
        body = <Step2Body 
            questionLabel={t(`${delete_profile_locale}.step${step}.question`)}
            passwordLabel={t(`${delete_profile_locale}.step${step}.password_label`)}
            showPassword={showPassword}
            endIconCallback={() => setShowPassword((status) => !status)}
        />
    }
    else if(step === 3){
        body = <Step3Body questionLabel={t(`${delete_profile_locale}.step${step}.question`)} caption={t(`${delete_profile_locale}.step${step}.caption`)}/>
    }

    const onSubmit = handleSubmit(async (data) => {
        console.log(data);

        try{
            if(step === 1){
                const customReason = data.otherReason.trim();

                if(customReason.length === 0){
                    const isAnyOptionClicked = data.found || data.not_satisfied || data.privacy || data.too_many_message || data.prefer_other || data.other;

                    if(!isAnyOptionClicked){
                        setRecentCheckBoxError(t(`${delete_profile_locale}.errors.select_option`));
                        return;
                    }
                }

                if(data.found && selectedFoundReason === ""){
                    setRecentCheckBoxError(t(`${delete_profile_locale}.errors.select_found`));
                    return;
                }

                setRecentCheckBoxError('');
                
                nextStep();
            }
            else if(step === 2){
                const firebaseResponse = await auth?.confirmPassword(data.password);
                console.log(firebaseResponse);

                nextStep();
            }
        }
        catch(error){
            console.log(error);

            setShowSnackBar({
                state: true,
                message: error.code === 'auth/invalid-credential' ? t(`${delete_profile_locale}.errors.invalid_firebase_password`) : `${error.code}`,
                color: "red"
            });
        }
    });

    const [
        deleteAccount,
        {
            isLoading,
            isSuccess,
            isError,
            error
        }
    ] = useDeleteAccountMutation();

    useEffect(() => {
        if(isSuccess){
            logAnalytics('PROFILE_PERMANENTLY_DELETED');

            setShowSnackBar({
                state: true,
                message: t(`${delete_profile_locale}.deleted`),
                color: "green"
            });
    
            auth?.logOut();
        }
    }, [isSuccess]);

    useEffect(() => {
        if(isError && error){
            setShowSnackBar({
                state: true,
                message: `${error.data.error.message}`,
                color: "red"
            });
        }
    }, [isError, error]);

    const deleteProfile = useCallback(async () => {
        let reason = value.otherReason.trim() + ",";
        
        if(value.found) reason += questions[0].value + selectedFoundReason + ",";
        if(value.not_satisfied) reason += questions[1].value + ",";
        if(value.privacy) reason += questions[2].value + ",";
        if(value.too_many_message) reason += questions[3].value + ",";
        if(value.prefer_other) reason += questions[4].value + ",";
        if(value.other) reason += questions[5].value;
        
        if(reason[reason.length - 1] === ","){
            reason = reason.substring(0, reason.length - 1);
        }

        if(reason[0] === ","){
            reason = reason.substring(1);
        }

        console.log(reason);

        await deleteAccount(reason);
    }, [value, selectedFoundReason]);

    const getPrimaryActionButton = () => {
        if(step === 1){
            return <ButtonComponent 
                text={t(`${delete_profile_locale}.continue`)}
                type="submit"
                width={103}
                sx={{
                    color: 'white',
                    backgroundColor: 'black'
                }}
            />
        }
        else if(step === 2){
            return <LoadingButtonComponent 
                text={t(`${delete_profile_locale}.continue`)}
                type="submit"
                inLoading={isSubmitting}
                width={103}
                sx={{
                    color: 'white',
                    backgroundColor: 'black'
                }}
            />
        }
        else {
            return <LoadingButtonComponent 
                text={t(`${delete_profile_locale}.delete_account`)}
                onClick={() => setOpenDeleteConfirmation(true)}
                width={103}
                sx={{
                    color: 'white',
                    backgroundColor: 'black'
                }}
            />
        }
    }

    return (
        <>
            <Stack 
                sx={{
                    mx: {xs: 2.5, md: 5},
                    height: '80%'
                }} 
                spacing={3}
            >
                <FormProviderComponent methods={methods} onSubmit={onSubmit}>
                    <Box sx={{height: '80%'}}>
                        {body}
                    </Box>

                    <Stack 
                        direction={{xs: 'column', md: 'row'}}
                        spacing={2}
                        display='flex'
                        sx={{
                            position: {xs: 'sticky', md: 'relative'},
                            bottom: 0,
                            pt: 5,
                            pb: {xs: 3, md: 10},
                            backgroundColor: 'white',
                            width: '100%'
                        }}
                    >
                        {getPrimaryActionButton()}

                        {
                            step === 2
                            ?
                            <ButtonComponent 
                                text={t(`${delete_profile_locale}.forgot_password`)}
                                onClick={() => navigate('/reset-password')}
                                variant='text'
                                width={103}
                                sx={{
                                    color: 'black'
                                }}
                            />
                            :
                            <ButtonComponent 
                                text={t(`${delete_profile_locale}.cancel`)}
                                onClick={() => navigate(-1)}
                                outlined
                                color="secondary"
                                width={103}
                                sx={{
                                    color: 'black'
                                }}
                            />
                        }
                    </Stack>
                </FormProviderComponent>
            </Stack>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />

            <DialogBox 
                title={t(`${delete_profile_locale}.delete_account`)}
                children={
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: 16,
                            color: '#6D6D6D'
                        }}
                    >
                        {t(`${delete_profile_locale}.step3.delete_confirmation`)}
                    </Typography>
                }
                open={openDeleteConfirmation} 
                onSuccess={deleteProfile}
                onClose={() => setOpenDeleteConfirmation(false)}
                submitInLoading={isLoading}
            />
        </>
    );
}

export default DeleteProfileBody;

DeleteProfileBody.propType = {
    step: PropTypes.number,
    updateStep: PropTypes.func
}

const Step1Body = ({
    questions, 
    checkboxLabel, 
    reasonLabel,
    helper,
    onChange, 
    error, 
    selectedValue
}) => {
    return <>
        <CheckBoxComponent 
            list={questions}
            label={checkboxLabel}
            onChange={onChange}
            required
            error={error}
            selectedValue={selectedValue}
        />

        <TextFieldComponent 
            name='otherReason'
            label={reasonLabel}
            sx={{
                width: {xs: '100%', md: '75%', lg: '50%'},
                mb: {xs: 15, md: 0}
            }}
            helper={helper}
            multiline
            rows={4}
        />
    </>
}

const Step2Body = ({
    questionLabel,
    passwordLabel,
    showPassword,
    endIconCallback
}) => {
    return <>
        <Typography
            sx={{
                fontWeight: 600,
                fontSize: {xs: 20, md: 24},
                color: '#6D6D6D',
                pb: 3
            }}
        >
            {questionLabel}
        </Typography>

        <TextFieldComponent 
            name='password'
            label={passwordLabel}
            required
            sx={{
                width: {xs: '100%', md: '75%', lg: '50%'}
            }}
            type={showPassword ? 'text' : 'password'}
            endIcon={
                !showPassword 
                ?
                <MdiHide style={{width: '20px', color: '#637381'}}/> 
                :
                <MdiShow style={{width: '20px', color: '#637381'}}/>
            }
            endIconCallback={endIconCallback}
        />
    </>
}

const Step3Body = ({
    questionLabel,
    caption
}) => {
    return <>
        <Typography
            sx={{
                fontWeight: 600,
                fontSize: {xs: 20, md: 24},
                color: '#6D6D6D',
                pb: 3
            }}
        >
            {questionLabel}
        </Typography>

        <Typography
            sx={{
                fontWeight: 400,
                fontSize: 16,
                color: '#6D6D6D'
            }}
        >
            {caption}
        </Typography>
    </>
}