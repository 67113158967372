import { 
    Card,
    Stack,
    Typography,
    Link,
    IconButton
} from "@mui/material";

import ChevronRightIcon from '../../../assets/icons/chevron-right-icon';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreatePartnerPreferenceMutation, useGetPartnerPreferenceDetailsQuery } from "../../../services/partner-preference-api";
import { useCallback, useEffect } from "react";
import SkeletonComponent from "../../../components/loading/skeleton-component";
import APIErrorLayout from "../../error/api-error/api-error-layout";

const PartnerPreferenceBody = () => {
    const { t } = useTranslation();
    const settings_locale = "partner_preference.preference";

    const options = [
        {label: t(`${settings_locale}.basic`), link: "/partner-basic-preference"},
        // {label: t(`${settings_locale}.religious`), link: "/partner-religious-preference"},
        {label: t(`${settings_locale}.professional`), link: "/partner-professional-preference"},
        // {label: t(`${settings_locale}.location`), link: "/partner-location-preference"},
        {label: t(`${settings_locale}.about`), link: "/partner-about-preference"}
    ];

    const navigate = useNavigate();

    const {isLoading: isLoadingPartnerPreference, isSuccess: isSuccessPartnerPreference, data: dataPartnerPreference, isError: isErrorPartnerPreference, error: errorPartnerPreference} = useGetPartnerPreferenceDetailsQuery();

    useEffect(() => {
        if(isSuccessPartnerPreference && dataPartnerPreference){
            console.log("Partner preference data...");
            console.log(dataPartnerPreference);
        }
    }, [isSuccessPartnerPreference, dataPartnerPreference]);

    const [
        createPartnerPreference,
        {
            isLoading: isLoadingCreatePartnerPreference,
            isSuccess: isSuccessCreatePartnerPreference,
            isError: isErrorCreatePartnerPreference,
            error: errorCreatePartnerPreference
        }
    ] = useCreatePartnerPreferenceMutation();

    useEffect(() => {
        if(isSuccessCreatePartnerPreference){
            console.log("Partner preference data created...");
        }
        else if(isErrorCreatePartnerPreference && errorCreatePartnerPreference){
            console.log("Partner preference data creation error...");
            console.log(errorCreatePartnerPreference);
        }
    }, [isSuccessCreatePartnerPreference, isErrorCreatePartnerPreference, errorCreatePartnerPreference]);

    useEffect(() => {
        if(isErrorPartnerPreference && errorPartnerPreference){
            console.log("Partner preference error...");
            console.log(errorPartnerPreference);

            if(errorPartnerPreference.status === 404){
                createPartnerPreference({
                    "minAge": 18,
                    "maxAge": 75,
                    "minHeight": 100,
                    "maxHeight": 250,
                    "maritalStatus": [],
                    "motherTongue": [],
                    "physicalStatus": [],
                    "bodyType": [],
                    "eatingHabits": [],
                    "drinksHabits": [],
                    "smokingHabits": [],
                    "religion": [],
                    "caste": [],
                    "subCaste": [],
                    "dosha": [],
                    "rashi": "",
                    "star": "",
                    "educationLevel": [],
                    "occupation": "",
                    "employmentType": [],
                    "currency": "",
                    "minAnnualIncome": 0,
                    "maxAnnualIncome": 999999,
                    "country": [],
                    "myExpectation": ""
                });
            }
        }
    }, [isErrorPartnerPreference, errorPartnerPreference]);

    const viewSection = useCallback((event, routeVal) => {
        console.log(routeVal);
        navigate(routeVal);
    }, []);

    return (
        <>
            <Card 
                sx={{
                    mx: {xs: 2.5, md: 5},
                    borderRadius: '8px',
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                    mb: 5
                }}
            >
                {
                    isLoadingPartnerPreference || isLoadingCreatePartnerPreference
                    ?
                    <SkeletonComponent width="100%" height="300px"/>
                    :
                    <>
                        {
                            (isErrorPartnerPreference && errorPartnerPreference.status !== 404) || isErrorCreatePartnerPreference
                            ?
                            <APIErrorLayout error={isErrorCreatePartnerPreference ? errorCreatePartnerPreference : errorPartnerPreference} />
                            :
                            <Stack>
                                {
                                    options.map((item, index) => {
                                        return <Link 
                                            onClick={(event) => viewSection(event, item.link)} 
                                            underline='none' 
                                            sx={{
                                                cursor: 'pointer', 
                                                width: '100%',
                                                '&:hover' : {
                                                    transform: 'scale(0.99)'
                                                },
                                            }}
                                        >
                                            <Stack 
                                                key={index}
                                                direction='row'
                                                justifyContent='space-between'
                                                alignItems='center' 
                                                sx={{
                                                    px: 5,
                                                    py: 2.5
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight: '500px',
                                                        fontSize: '16px',
                                                        color: '#3D3D3D'
                                                    }}
                                                >
                                                    {item.label}
                                                </Typography>

                                                <IconButton 
                                                    aria-label="arrow" 
                                                    size="small" 
                                                    onClick={() => {}}
                                                >
                                                    <ChevronRightIcon style={{width: '24px', height: '24px', color: '#3D3D3D'}}/>
                                                </IconButton>
                                            </Stack>
                                        </Link>
                                    })
                                }
                            </Stack>
                        }
                    </>
                }
            </Card>
        </>
    );
}

export default PartnerPreferenceBody;