import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormProviderComponent from '../form/form-provider-component';
import { CardActions, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { LoadingButtonComponent, TextFieldComponent } from '../form';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { encrypt, decrypt } from "../../contexts/utils";
import { useState } from 'react';
import SnackbarComponent from '../snack-bar/snack-bar-component';
import { useReRequestOtpMutation, useVerifyOtpMutation } from '../../services/auth-api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from '../../contexts/analytics/analytics-provider';

export default function MobileOtpFormComponent({mobileNum, successCallback}) {
  const theme = useTheme();

  const { t } = useTranslation();
  const otp_form_locale = 'authentication.mobile.otp';
  const mobile_form_locale = 'authentication.mobile.number';

  const navigate = useNavigate();

  const {logCustomEvent, logCustomEventWithParams} = useAnalytics();
  const logAnalytics = useCallback((event) => {
    logCustomEvent(event);
  }, []);
  const logAnalyticsWithParam = useCallback((event, param) => {
    logCustomEventWithParams(event, param);
  }, []);

  const [showSnackBar, setShowSnackBar] = useState({
    state: false,
    message: "",
    color: ''
  });

  const otpSchema = yup.object().shape({
    otp: yup.string().required(t(`${otp_form_locale}.errors.otp_is_required`)).test('isValidOtp', t(`${otp_form_locale}.errors.invalid_otp`), () => {
      const isValid = IsOtpValid();
      return isValid;
    })
  });

  const defaultOtpValues = {
    otp: ''
  };

  const otpMethods = useForm({
    resolver: yupResolver(otpSchema),
    defaultValues: defaultOtpValues
  });

  const {
    reset: resetOtpForm,
    handleSubmit: handleOtpSubmit,
    formState: { isSubmitting: isSubmittingOtp },
    watch: otpFormWatch
  } = otpMethods;

  const otpFormValue = otpFormWatch();

  const [
    verifyOtpAPI,
    {
      isLoading: isLoadingVerifyOtp,
      isSuccess: isVerifyOtpSuccess,
      data: verifyOtpData,
      isError: isErrorVerifyOtp,
      error: verifyOtpError
    }
  ] = useVerifyOtpMutation();

  const [
    reRequestOtpAPI,
    {
      isLoading: isLoadingReRequestOtp,
      isSuccess: isReRequestOtpSuccess,
      data: reRequestOtpData,
      isError: isErrorRerequestOtp,
      error: reRequestOtpError
    }
  ] = useReRequestOtpMutation();

  const IsOtpValid = () => {
    return otpFormValue.otp.trim().length === 6;
  }

  const showMobileNumber = () => {
    let number = "";

    if(mobileNum.length >= 3){
      number = mobileNum.substring(0, 3);

      if(mobileNum.length >= 6){
        for(let i = 3; i < mobileNum.length - 3; i++){
          number += "*"
        }

        number += mobileNum.substring(mobileNum.length - 3, mobileNum.length);
      }
    }

    return number;
  }

  const resentOtp = useCallback(async () => {
    console.log(mobileNum);

    const body = {
      "mobileNumber": mobileNum
    }

    logAnalytics('RE_REQUEST_OTP');

    await reRequestOtpAPI(body);
    
  }, [mobileNum]);

  const verifyOtp = handleOtpSubmit(async(data) => {
    console.log(data);

    const otpSessionID = decrypt(localStorage.getItem('otpSessionID'));
    console.log(otpSessionID);

    try{
      const body = {
        "sessionId": otpSessionID,
        "mobileNumber": mobileNum,
        "otp": data.otp
      }

      console.log(body);

      logAnalytics('ATTEMPT_OTP_VERIFICATION');

      await verifyOtpAPI(body);
    }
    catch(error){
      console.log(error);

      setShowSnackBar({
        state: true,
        message: otpSessionID ? t(`${otp_form_locale}.errors.something_went_wrong`) : t(`${otp_form_locale}.errors.try_again`),
        color: "red"
      });
    }
  });

  const adminCreatedUserNumberVerified = useCallback(() => {
    logAnalytics('ADMIN_CREATED_USER_MOBILE_VERIFIED');
    successCallback();
  }, []);

  useEffect(() => {
    if(isVerifyOtpSuccess && verifyOtpData){
      console.log(verifyOtpData);
      console.log(verifyOtpData.status);

      localStorage.removeItem('otpSessionID');

      resetOtpForm();

      // New user
      if(verifyOtpData.success){
        logAnalytics('MOBILE_NUMBER_VERIFIED');
        navigate('/signup');
      }
      // User created in dashboard and verified
      else{
        adminCreatedUserNumberVerified();
      }
    }
  }, [isVerifyOtpSuccess, verifyOtpData, navigate, resetOtpForm]);

  useEffect(() => {
    if(isErrorVerifyOtp && verifyOtpError){
      console.log(verifyOtpError);
      console.log(verifyOtpError.status);

      // User created in dashboard and verified
      if(verifyOtpError.status === 202){
        adminCreatedUserNumberVerified();
      }
      else{
        logAnalyticsWithParam('OTP_VERIFICATION_FAILED', {message: verifyOtpError.data.error.message});

        setShowSnackBar({
          state: true,
          message: `${verifyOtpError.data.error.message}`,
          color: "red"
        });
      }
    }
  }, [isErrorVerifyOtp, verifyOtpError]);

  useEffect(() => {
    if(isReRequestOtpSuccess && reRequestOtpData){
      console.log(reRequestOtpData);
      console.log(reRequestOtpData.status);

      const otpSessionID = encrypt(reRequestOtpData.data);
      localStorage.setItem('otpSessionID', otpSessionID);

      setShowSnackBar({
        state: true,
        message: t(`${mobile_form_locale}.otp_sent`),
        color: "green"
      });
    }
  }, [isReRequestOtpSuccess, reRequestOtpData]);

  useEffect(() => {
    if(isErrorRerequestOtp && reRequestOtpError){
      console.log(reRequestOtpError);
      console.log(reRequestOtpError.status);

      let message = t(`${otp_form_locale}.errors.something_went_wrong`);

      //Otp already delivered and still valid
      if(reRequestOtpError.status === 429){
        message = t(`${mobile_form_locale}.errors.otp_still_active`);
      }
      else{
        message = reRequestOtpError.data.error?.message;
      }

      setShowSnackBar({
        state: true,
        message: `${message}`,
        color: "red"
      });
    }
  }, [isErrorRerequestOtp, reRequestOtpError]);

  return (
    <>
      <FormProviderComponent methods={otpMethods} onSubmit={verifyOtp}>
        <CardContent>
          <img 
            src="/assets/images/hero/hero-otp.png" 
            alt="otp banner"
            style={{
            width: '100%',
            marginBottom: 30,
            borderRadius: 16
          }}
        />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 20,
              color: '#6D6D6D',
              mb: 3,
              textAlign: 'center'
            }}
          >
            {`${t(`${otp_form_locale}.form.introduction1`)} ${showMobileNumber()} ${t(`${otp_form_locale}.form.introduction2`)}`}
          </Typography>

          <Grid xs={12} container direction='row' alignItems='center'> 
            <Grid xs={9} sx={{pr: 3}}>
              <TextFieldComponent 
                label={t(`${otp_form_locale}.form.otp_code_label`)}
                name='otp'
              />
            </Grid>

            <Grid xs={3}>
              <LoadingButtonComponent 
                text={t(`${otp_form_locale}.form.action`)} 
                inLoading={isLoadingVerifyOtp}
                disabled={isLoadingReRequestOtp}
                type='submit'
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions sx={{justifyContent: 'center'}}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 16,
              color: '#6D6D6D',
              mb: 3,
              textAlign: 'center'
            }}
          >
            {t(`${otp_form_locale}.form.question`)} 

            <span>
              <button 
                type='button'
                style={{
                  fontFamily: 'Inter', 
                  fontWeight: 600, 
                  fontSize: 16,
                  color: theme.palette.primary.main,
                  backgroundColor: '#ffffff',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={resentOtp}
              >
                {t(`${otp_form_locale}.form.question_action`)}
              </button>
            </span>
          </Typography>
        </CardActions>
      </FormProviderComponent>

      <SnackbarComponent 
        message={showSnackBar.message}
        open={showSnackBar.state}
        handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
        color={showSnackBar.color}
      />
    </>
  );
}