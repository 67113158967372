import { useCallback, useEffect, useState } from "react";
import ProfileNavigationBar from "../navigation/profile-navigation-bar";
import ProfileHeader from "./profile-header";

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ProfileBasicLayout from "./basic/profile-basic-details-layout";
import ProfileProfessionalLayout from './professional/profile-professional-details-layout';
import ProfileFamilyLayout from './family/profile-family-details-layout';
import ProfileHoroscopeLayout from './horoscope/profile-horoscope-details-layout';
import ProfileHobbiesLayout from './hobbies&Interest/profile-hobbies-details-layout';
import ProfileGalleryLayout from './gallery/profile-gallery-layout';
import { Box, Stack, Typography } from "@mui/material";
import ProfileFeedWarningCard from "../profile-general/profile-feed/profile-feed-warning-card";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from 'react-router';

import Footer from '../footer/footer';
import { useGetProfileDetailsMutation, useGetOtherProfileDetailsMutation } from "../../services/profile-api";
import { useAuth } from "../../contexts/auth/auth-provider";
import APIErrorLayout from "../error/api-error/api-error-layout";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { ButtonComponent } from "../../components/form";

const ProfileLayout = () => {
    const params = useParams();

    const { t } = useTranslation();
    const profile_locale = "profile.alert";
    const validation_locale = "validation";

    const auth = useAuth();
    const navigate = useNavigate();

    const [value, setValue] = useState(0);

    const [privateView, setPrivateView] = useState(true);
    const [myAccount, setMyAccount] = useState(false);

    const [profileDetails, setProfileDetails] = useState(null);
    const [profileUpdateDetails, setProfileUpdateDetails] = useState(null);

    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const [hideProfileInfo, setHideProfileInfo] = useState({
        show: true,
        type: ""
    });

    const [
        getMyProfileDetails, 
        {
            isLoading: isLoadingMyProfileDetails, 
            isSuccess: isSuccessMyProfileDetails, 
            data: dataMyProfileDetails, 
            isError: isErrorMyProfileDetails, 
            error: errorMyProfileDetails
        }
    ] = useGetProfileDetailsMutation();

    const [
        getOtherProfileDetails, 
        {
            isLoading: isLoadingOtherProfileDetails, 
            isSuccess: isSuccessOtherProfileDetails, 
            data: dataOtherProfileDetails, 
            isError: isErrorOtherProfileDetails, 
            error: errorOtherProfileDetails
        }
    ] = useGetOtherProfileDetailsMutation();

    useEffect(() => {
        if(isSuccessMyProfileDetails && dataMyProfileDetails){
            console.log("Profile data...");
            console.log(dataMyProfileDetails);

            setProfileDetails(dataMyProfileDetails.data);

            const data = dataMyProfileDetails.data;

            // Some attributes are different from get response object
            setProfileUpdateDetails(
                {
                    "createdBy": data.createdBy,
                    "motherTongue": data.motherTongue ?? "",
                    "religion": data.religion ?? "",
                    "dateOfBirth": data.dateOfBirth ?? "",
                    "gender": data.gender,
                    "maritalStatus": data.maritalStatus ?? "",
                    "height": data.height,
                    "physicalStatus": data.physicalStatus,
                    "bodyType": data.bodyType,
                    "education": data.education ?? "",
                    "educationLevel": data.educationLevel,
                    "occupation": data.occupation ?? "",
                    "employmentType": data.employmentType ?? "",
                    "currency": data.currency ?? "",
                    "annualIncome": data.annualIncome,
                    "timeOfBirth": data.timeOfBirth ?? "",
                    "horoscopes":  data.horoscopes ?? [],
                    "nawamsam": data.nawamsam ?? [],
                    "citizenship": data.citizenship ?? "",
                    "countryOfLiving": data.countryOfLiving ?? "",
                    "residenceStatus": data.residenceStatus ?? "",
                    "hometown":  data.hometown ?? "",
                    "city": data.city ?? "",
                    "birthCountry": data.birthCountry ?? "",
                    "bio": data.bio ?? "",
                    "profileImages":  data.profileImages ?? [],
                    "reasonForDelete": "",
                    "caste": data.caste ?? "",
                    "subCaste": data.subCaste ?? "",
                    "rashi": data.rashi,
                    "star": data.star ?? "",
                    "dosham": data.dosham ?? "",
                    "eatingHabits": data.eatingHabits,
                    "drinkingHabits": data.drinkingHabits,
                    "smokingHabits": data.smokingHabits,
                    "familyType": data.familyType ?? null,
                    "mothersOccupation": data.motherOccupation ?? "",// This is different from get response 
                    "fathersOccupation": data.fatherOccupation ?? "",// This is different from get response 
                    "numberOfSisters": data.numberOfSisters,
                    "numberOfBrothers": data.numberOfBrothers,
                    "aboutFamily": data.aboutFamily ?? "",
                    "hobbiesInterests": data.hobbiesInterests ?? []
                }
            );
        }
    }, [isSuccessMyProfileDetails, dataMyProfileDetails]);

    useEffect(() => {
        if(isSuccessOtherProfileDetails && dataOtherProfileDetails){
            console.log("Profile data...");
            console.log(dataOtherProfileDetails);

            if(dataOtherProfileDetails.data.deactivated && !dataOtherProfileDetails.data.firstName){
                setHideProfileInfo({
                    show: false,
                    type: "DEACTIVATED"
                });
            }
            else if(dataOtherProfileDetails.data.blocked && !dataOtherProfileDetails.data.firstName){
                setHideProfileInfo({
                    show: false,
                    type: "BLOCKED"
                });
            }
            else{
                setProfileDetails(dataOtherProfileDetails.data);
            }
        }
    }, [isSuccessOtherProfileDetails, dataOtherProfileDetails]);

    useEffect(() => {
        if(isErrorOtherProfileDetails && errorOtherProfileDetails){
            console.log("Profile data error...");
            console.log(errorOtherProfileDetails);

            if(errorOtherProfileDetails.status === 404){
                setHideProfileInfo({
                    show: false,
                    type: "NOT_FOUND"
                });
            }
        }
    }, [isErrorOtherProfileDetails, errorOtherProfileDetails]);

    const callMyProfileAPI = useCallback(async () => {
        logAnalytics('VISIT_OWN_PROFILE_PAGE');
        await getMyProfileDetails();
    }, []);

    const callOthersProfileAPI = useCallback(async (memberID) => {
        logAnalytics('VISIT_OTHER_PROFILE_PAGE');
        await getOtherProfileDetails(memberID);
    }, []);

    useEffect(() => {
        if(params && auth && auth.currentUser){
            if(params.id === auth.currentUser.uuid){
                setMyAccount(true);
                setPrivateView(true);

                callMyProfileAPI();
            }
            else{
                setMyAccount(false);
                setPrivateView(false);

                callOthersProfileAPI(params.id);
            }
        }
    }, [params, auth]);

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
    });

    const getUnavailableProfileBody = () => <Stack py={10} px={2} direction='column' spacing={5} alignItems='center'>
        <Typography
            sx={{
                fontWeight: 600,
                fontSize: 16,
                textAlign: 'center'
            }}
        >
            {
                hideProfileInfo.type === 'DEACTIVATED'
                ?
                t(`${validation_locale}.user_deactivated`)
                :
                hideProfileInfo.type === 'BLOCKED' ? t(`${validation_locale}.user_blocked_you`) : t(`${validation_locale}.user_not_found`)
            }
        </Typography>

        <ButtonComponent 
            text={t(`${profile_locale}.explore_more`)}
            onClick={() => navigate("/matches")}
            sx={{
                width: '150px',
                "&:hover": {
                    transform: 'scale(1.03)'
                }
            }}
            textSx={{
                textTransform: 'capitalize'
            }}
        />
    </Stack>

    let body;

    if(isErrorMyProfileDetails && errorMyProfileDetails){
        console.log("Profile error...");
        console.log(errorMyProfileDetails);

        body = <APIErrorLayout error={errorMyProfileDetails} />
    }
    else if(isErrorOtherProfileDetails && errorOtherProfileDetails){
        console.log("Profile error...");
        console.log(errorOtherProfileDetails);

        if(errorOtherProfileDetails.status === 404){
            body = <>
                <ProfileHeader 
                    tabValue={value} 
                    setTabValue={handleChange} 
                    privateView={privateView} 
                    changePrivateView={setPrivateView}
                    myAccount={myAccount}
                    responseData={profileDetails}
                    isLoading={false}
                    editCallback={callOthersProfileAPI}
                    hideProfile={true}
                />

                {getUnavailableProfileBody()}
            </>
        }
        else{
            body = <APIErrorLayout error={errorOtherProfileDetails} />
        }
    }
    else {
        body = <>
            <ProfileHeader 
                tabValue={value} 
                setTabValue={handleChange} 
                privateView={privateView} 
                changePrivateView={setPrivateView}
                myAccount={myAccount}
                responseData={profileDetails}
                isLoading={isLoadingMyProfileDetails || isLoadingOtherProfileDetails}
                editCallback={callOthersProfileAPI}
                hideProfile={!hideProfileInfo.show}
            />

            {
                hideProfileInfo.show
                ?
                <Box sx={{px: {xs: 2, md: 7}, pb: 5}}>
                    <TabContext value={value}>
                        {/* basic_details */}
                        <TabPanel value={0} sx={{m: 0, px: 0}}>
                            <ProfileBasicLayout 
                                privateView={privateView} 
                                isLoading={isLoadingMyProfileDetails || isLoadingOtherProfileDetails}
                                data={profileDetails}
                                editCallback={callMyProfileAPI}
                                updateData={profileUpdateDetails}
                                myAccount={myAccount}
                            />
                        </TabPanel>

                        {/* professional_details */}
                        <TabPanel value={1} sx={{m: 0, px: 0}}>
                            <ProfileProfessionalLayout 
                                privateView={privateView}
                                isLoading={isLoadingMyProfileDetails || isLoadingOtherProfileDetails}
                                data={profileDetails}
                                editCallback={callMyProfileAPI}
                                updateData={profileUpdateDetails}
                            />
                        </TabPanel>

                        {/* family_details */}
                        <TabPanel value={2} sx={{m: 0, px: 0}}>
                            <ProfileFamilyLayout 
                                privateView={privateView}
                                isLoading={isLoadingMyProfileDetails || isLoadingOtherProfileDetails}
                                data={profileDetails}
                                editCallback={callMyProfileAPI}
                                updateData={profileUpdateDetails}
                            />
                        </TabPanel>

                        {/* horoscope_details */}
                        <TabPanel value={3} sx={{m: 0, px: 0}}>
                            <ProfileHoroscopeLayout 
                                privateView={privateView}
                                isLoading={isLoadingMyProfileDetails || isLoadingOtherProfileDetails}
                                data={profileDetails}
                                editCallback={callMyProfileAPI}
                                updateData={profileUpdateDetails}
                            />
                        </TabPanel>

                        {/* hobbies_interests */}
                        <TabPanel value={4} sx={{m: 0, px: 0}}>
                            <ProfileHobbiesLayout 
                                privateView={privateView}
                                isLoading={isLoadingMyProfileDetails || isLoadingOtherProfileDetails}
                                data={profileDetails}
                                updateData={profileUpdateDetails}
                                editCallback={callMyProfileAPI}
                            />
                        </TabPanel>

                        {/* gallery */}
                        <TabPanel value={5} sx={{m: 0, px: 0}}>
                            <ProfileGalleryLayout 
                                privateView={privateView}
                                isLoading={isLoadingMyProfileDetails || isLoadingOtherProfileDetails}
                                data={profileDetails}
                                updateData={profileUpdateDetails}
                                editCallback={callMyProfileAPI}
                            />
                        </TabPanel>
                    </TabContext>

                    {
                        privateView
                        ?
                        <ProfileFeedWarningCard 
                            title={t(`${profile_locale}.title`)}
                            caption=""
                            actionTxt={t(`${profile_locale}.action`)}
                            action="/partner-preference"
                            needHeart={false}
                        />
                        :
                        <></>
                    }
                </Box>
                :
                getUnavailableProfileBody()
            }
        </>
    }

    return (
        <>
            <ProfileNavigationBar />

            {body}
            
            <Footer />
        </>
    );
}

export default ProfileLayout;