import PropTypes from 'prop-types';
//
import * as React from 'react';
//
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Divider, Stack } from '@mui/material';

import CloseIcon from '../../assets/icons/close-icon';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function DialogBox({ 
    children, 
    title, 
    subTitle = '',
    approveText = "", 
    closeText = "", 
    customFooter = false,
    maxWidth='sm',
    open, 
    onClose, 
    onSuccess,
    hideClose = false,
    submitInLoading=false
}) {
    const { t } = useTranslation();
    const dialog_locale = "dialog";

    const theme = useTheme();

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={maxWidth}
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid
                        xs
                        container
                        display='flex'
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid xs={11}>
                            <Typography 
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: 20,
                                    pr: 1
                                }}
                            >
                                {title}
                            </Typography>

                            {
                                subTitle !== ''
                                ?
                                <Typography 
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: 12,
                                        color: '#6D6D6D'
                                    }}
                                >
                                    {subTitle}
                                </Typography>
                                :
                                <></>
                            }
                        </Grid>

                        <Grid xs={1} justifyContent='flex-end'>
                            <IconButton aria-label="close" size="medium" sx={{backgroundColor: '#E7E7E7'}} onClick={onClose}>
                                <CloseIcon style={{width: 20, height: 20, color: 'black'}}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <Divider sx={{mt: -1, mb: 2}}/>

                <DialogContent>
                    {children}
                </DialogContent>

                {!customFooter ? <Divider sx={{mt: 2}}/> : <></>}

                <DialogActions sx={{my: 0, mx: 2}}>
                    {
                        !customFooter
                        ?
                        <Stack 
                            direction={{xs: 'column-reverse', md: 'row'}} 
                            spacing={2} 
                            sx={{width: '100%', my: 2}}
                            justifyContent='end'
                        >
                            {
                                !hideClose
                                ?
                                <Button 
                                    variant="outlined" 
                                    color='primary' 
                                    size="large"
                                    sx={{
                                        width: {xs: '100%', md: 140}, 
                                        "&:hover": {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                    onClick={onClose}
                                >
                                    {closeText !== "" ? closeText : t(`${dialog_locale}.cancel`)}
                                </Button>
                                :
                                <></>
                            }
                            
                            <LoadingButton 
                                variant="contained"
                                color='primary'
                                size="large"
                                loading={submitInLoading}
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                    color: 'white',
                                    "&:hover": {
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white'
                                    }
                                }}
                                onClick={onSuccess} 
                                disabled={submitInLoading}
                            >
                                {approveText !== "" ? approveText : t(`${dialog_locale}.yes`)}
                            </LoadingButton>
                        </Stack>
                        :
                        <></>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

DialogBox.propTypes = {
    children: PropTypes.object,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    approveText: PropTypes.string,
    closeText: PropTypes.string,
    maxWidth: PropTypes.string,
    customFooter: PropTypes.bool,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    hideClose: PropTypes.func,
    submitInLoading: PropTypes.bool
};